import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Spinner(props) {
  return (
    // {props.fullScreen}
    <Box sx={{height: '100%', display: 'flex', justifyContent: props.align ? props.align : 'center' , alignItems: 'center' }}>
      <CircularProgress size={props.size}  />
    </Box>
  );
}
