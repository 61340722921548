import React, { useEffect, useState } from "react";
import { AssistWalker, EmojiEvents, SportsSoccer } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import CardIcon from "../helper-components/icons/CardIcon";
import SoccerBoot from "../helper-components/icons/SoccerBoot";
import { useTranslation } from "react-i18next";

const GameStats = (props) => {
  //Translations
  const { t } = useTranslation();
  const [sortedBy, setSortedBy] = useState("playerName");
  const [sortOrder, setSortOrder] = useState("asc");
  const { teamPlayers, games } = props;

  // Calculate statistics based on players and games
  const playerStatistics = teamPlayers.map((player) => {
    // const { games, name } = player;
    let totalGoals = 0;
    let totalAssists = 0;
    let totalMVP = 0;
    let totalLVP = 0;
    let totalRedCards = 0;
    let totalYellowCards = 0;

    games.forEach((game) => {
      const goalsInGame = game.goalScorers.filter(
        (scorer) => scorer === player.id
      ).length;
      totalGoals += goalsInGame;
      const assistsInGame = game.assistMakers.filter(
        (assistmaker) => assistmaker === player.id
      ).length;
      totalAssists += assistsInGame;
      totalMVP += game.mvp === player.id ? 1 : 0;
      totalLVP += game.lvp === player.id ? 1 : 0;
      const yellowCardsInGame = game.yellowCards.filter(
        (yellowCardTaker) => yellowCardTaker === player.id
      ).length;
      totalYellowCards += yellowCardsInGame;
      const redCardsInGame = game.redCards.filter(
        (redCardTaker) => redCardTaker === player.id
      ).length;
      totalRedCards += redCardsInGame;
    });

    return {
      playerId: player.id,
      playerName: player.name,
      playerImageURL: player.imageURL,
      totalGoals,
      totalAssists,
      totalMVP,
      totalLVP,
      totalYellowCards,
      totalRedCards,
    };
  });

  // Sort player statistics based on selected column
  const handleSort = (property) => {
    const isAscending = sortedBy === property && sortOrder === "asc";
    setSortedBy(property);
    setSortOrder(isAscending ? "desc" : "asc");
  };

  const sortedPlayerStatistics = playerStatistics.sort((a, b) => {
    const isAscending = sortOrder === "asc" ? 1 : -1;
    if (a[sortedBy] < b[sortedBy]) {
      return -1 * isAscending;
    }
    if (a[sortedBy] > b[sortedBy]) {
      return 1 * isAscending;
    }
    return 0;
  });
  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "100vw", overflowX: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("general.tablecolumns.player")}</TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalGoals"}
                direction={sortOrder}
                onClick={() => handleSort("totalGoals")}
              >
                <SportsSoccer />
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalAssists"}
                direction={sortOrder}
                onClick={() => handleSort("totalAssists")}
              >
                <SoccerBoot />
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalMVP"}
                direction={sortOrder}
                onClick={() => handleSort("totalMVP")}
              >
                <EmojiEvents />
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalLVP"}
                direction={sortOrder}
                onClick={() => handleSort("totalLVP")}
              >
                <AssistWalker />
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalYellowCards"}
                direction={sortOrder}
                onClick={() => handleSort("totalYellowCards")}
              >
                <CardIcon color={"yellow"} />
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalRedCards"}
                direction={sortOrder}
                onClick={() => handleSort("totalRedCards")}
              >
                <CardIcon color={"red"} />
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPlayerStatistics.map((player) => (
            <TableRow key={player.playerId}>
              <TableCell>
                {player.playerImageURL == null && player.playerName}
                {player.playerImageURL != null && (
                  <Box>
                  <Avatar
                    key={player.playerId}
                    src={
                      player.playerImageURL != null
                        ? player.playerImageURL
                        : "genericUserImageURL"
                    }
                    alt={player.playerName}
                    sx={ player.imageURL === null ? { bgcolor: player.color}: {bgcolor:"none"} }
                  />
                  <Box>{player.playerName} </Box>
                  </Box>
                )}
              </TableCell>
              <TableCell padding="none">{player.totalGoals}</TableCell>
              <TableCell padding="none">{player.totalAssists}</TableCell>
              <TableCell padding="none">{player.totalMVP}</TableCell>
              <TableCell padding="none">{player.totalLVP}</TableCell>
              <TableCell padding="none">{player.totalYellowCards}</TableCell>
              <TableCell padding="none">{player.totalRedCards}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GameStats;
