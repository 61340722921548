import React, { useEffect, useState } from "react";
import { Close, Gavel, HourglassEmpty, Savings } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Fab,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Portal,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HeaderIconTextComponent } from "../Team/MyTeamHeader";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase-config";
import moment from "moment";

const FineDetailDetailTab = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  let fineDetails = props.fineDetails;
  let tempTeamPlayers = props.teamPlayers;
  const drawerBleeding = 56;
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSubmit = async () => {
    let finalArr = selectedTeamPlayers.map((obj) => {
      return obj.id;
    });

    finalArr.forEach(async (finedPlayerId) => {
      //create fine-line
      await addDoc(collection(db, "fine-lines"), {
        player: finedPlayerId,
        fine: props.fineId,
        created: moment()._d,
        paid: false,
      });
    });
    props.callBack(!props.rerenderValue);
    let succesText =
      finalArr.length > 1
        ? t("fines.messages.addfinessuccess")
        : t("fines.messages.addfinesuccess")
    toast.success(succesText);
    setOpen(false);
  };

  const handleDeleteFromSelectedPlayers = (value) => {
    setSelectedTeamPlayers(
      selectedTeamPlayers.filter((player, index) => index !== value)
    );
  };

  useEffect(() => {
    setTeamPlayers(tempTeamPlayers);
  }, [props]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 1,
        textAlign: "center",
      }}
    >
      <Typography variant="h5">{fineDetails.name}</Typography>
      <Typography variant="caption">
        {t("fines.finedetails.amount")}:
        {t("intlCurrencyWithOptions", { val: fineDetails.amount })}
      </Typography>
      <Box sx={{ display: "flex", color: "grey", mt: 2 }}>
        {HeaderIconTextComponent(
          <Savings />,
          <span style={{ color: "green" }}>
            {t("intlCurrencyWithOptions", { val: props.amounts.amountPaid })}
          </span>,
          t("fines.finedetails.amountpaid")
        )}
        {HeaderIconTextComponent(
          <HourglassEmpty />,
          <span style={{ color: "red" }}>
            {t("intlCurrencyWithOptions", {
              val: props.amounts.amountToBePaid,
            })}
          </span>,
          t("fines.finedetails.amountopen")
          )}
      </Box>
      {teamPlayers.length > 0 && (
        <Box>
          <SwipeableDrawer
            className="swipeableDrawer"
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={0}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {open && (
              <Box
                sx={{
                  position: "absolute",
                  top: open ? -drawerBleeding : 0,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  visibility: "visible",
                  right: 0,
                  left: 0,
                  display: "flex",
                  p: 2,
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    textAlign: "center",
                    flex: "1",
                  }}
                >
                  {t("fines.finedetails.issuefine")}
                </Typography>
                <IconButton sx={{ p: 0 }} onClick={toggleDrawer(false)}>
                  <Close sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <List>
                  {teamPlayers.map((player, index) => (
                    <ListItemButton
                      onClick={() => {
                        setSelectedTeamPlayers([
                          ...selectedTeamPlayers,
                          player,
                        ]);
                      }}
                      key={index}
                    >
                      <Gavel />
                      <ListItemText primary={player.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Box>
              {selectedTeamPlayers.length > 0 && (
                <Box>
                  {selectedTeamPlayers.map((player, index) => (
                    <Chip
                      label={player.name}
                      key={index}
                      onDelete={() => handleDeleteFromSelectedPlayers(index)}
                    />
                  ))}
                </Box>
              )}
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                autoFocus
              >
                {t("fines.finedetails.issuefine")}
              </Button>
            </Box>
          </SwipeableDrawer>
        </Box>
      )}

      <Portal container={document.body}>
        <Fab
          sx={theme.fabStyle}
          color="primary"
          aria-label="add"
          onClick={toggleDrawer(true)}
        >
          <Gavel />
        </Fab>
      </Portal>
    </Box>
  );
};

export default FineDetailDetailTab;
