import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";

export const getTotalDrinksPerPlayer = async (playerId, type) => {
  let drinksArray = [];
  let playerRef = doc(db, "users", playerId);
  const q = query(
    collection(db, "drink-lines"),
    where("createdFor", "==", playerRef),
    orderBy("createdAt", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    const userData = await getDoc(doc.data().createdBy);
    drinksArray.push({
      id: doc.id,
      details: doc.data(),
      player: userData.data(),
    });
  });
  // console.log(totalDrinksBought)
  return { drinksArray: drinksArray };
};

export const getTotalDrinksPerPlayer2 = async (playerId) => {
  let tempDrinksArray = [];
  let playerRef = doc(db, "users", playerId);
  const q = query(
    collection(db, "drink-lines"),
    where("createdFor", "==", playerRef),
    orderBy("createdAt", "desc")
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    tempDrinksArray.push({ id: doc.id, details: doc.data() });
  });
  return {player: playerId, drinks: tempDrinksArray};
};

