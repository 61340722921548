import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Menu from "@mui/material/Menu";
import { NavLink, useLocation } from "react-router-dom";

import {
  Home,
  SportsBar,
  Sports,
  BarChart,
  Groups,
  Gavel,
  ArrowBack,
  Casino,
} from "@mui/icons-material";

//Translations
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";
import { useUserAuth } from "../context/userAuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { useTheme } from "@mui/material";

const drawerWidth = 240;

function ResponsiveAppBar(props) {
  const [team, setTeam] = useState({});
  const { logOut, user } = useUserAuth();
  const [noTeam, setNoTeam] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState();
  const location = useLocation();

  const userRef = doc(db, "users", user.uid);
  const fetchTeamForPlayer = async () => {
    const userData = await getDoc(userRef);
    setFirebaseUser(userData.data());

    if (userData.data().team !== null) {
      const userTeam = await getDoc(userData.data().team);
      if (userTeam.data() == null) {
        console.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));
      }
      let userTeamObj = userTeam.data();
      userTeamObj.id = userTeam.id;
      setTeam(userTeamObj);
    } else {
      setTeam({ name: "TEAM" });
      setNoTeam(true);
      window.location.href = "/team";
    }
  };

  const { t } = useTranslation();
  const theme = useTheme();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleMenuItemClick(path) {
    setMobileOpen(false);
    navigate(`/${path}`);
  }

  const settings = [
    // {
    //   name: t("settingsmenu.profile"),
    //   action: () => handleMenuItemClick("profile"),
    // },
    {
      name: t("settingsmenu.settings"),
      action: () => handleMenuItemClick("settings"),
    },
    { name: t("settingsmenu.logout"), action: handleLogout },
  ];

  const navMenu = [
    { name: t("navmenu.home"), path: "/home", icon: <Home /> },
    { name: t("navmenu.drinks"), path: "/drinks", icon: <SportsBar /> },
    { name: t("navmenu.games"), path: "/games", icon: <Sports /> },
    { name: t("navmenu.stats"), path: "/statistics", icon: <BarChart /> },
    { name: t("navmenu.myteam"), path: "/team", icon: <Groups /> },
    { name: t("navmenu.fines"), path: "/fines", icon: <Gavel /> },
    { name: t("navmenu.finewheel"), path: "/finewheel", icon: <Casino /> },
  ];

  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {navMenu.map((item) => (
          <NavLink
            to={`${item.path}`}
            key={item.name}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem disablePadding>
              <ListItemButton
                selected={
                  "/" + location.pathname.split("/")[1] === item.path
                    ? true
                    : false
                }
                onClick={() => setMobileOpen(false)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    fetchTeamForPlayer();
  }, []);
  return (
    firebaseUser && (
      <Box sx={{ display: "flex" }}>
        <AppBar
          className="inverted-border-radius"
          position="fixed"
          elevation={0}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            {props.backButton === undefined && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}
            {props.backButton !== undefined && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => navigate(`${props.backButton}`)}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: { xs: "center", sm: "start" },
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: "flex",
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {team && team.name && team.name.toUpperCase()}
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ zIndex: 5 }}
                    alt={user && user.displayName}
                    src={
                      firebaseUser.imageURL && firebaseUser.imageURL.length > 0
                        ? firebaseUser.imageURL
                        : user.photoURL
                    }
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.name} onClick={setting.action}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: 0, sm: 3 },
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    )
  );
}

export default ResponsiveAppBar;
