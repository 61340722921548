import emptyClubLogo from "../../../src/media/emptyClubLogo.png";
import g7Logo from "../../../src/media/transparant_logo.png";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fab,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import Spinner from "../helper-components/Spinner";
import { Add, Close } from "@mui/icons-material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useUserAuth } from "../../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../helperFunctions/convertDate";
import { useTranslation } from "react-i18next";

const Games = () => {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();

  const [firebaseUser, setFirebaseUser] = useState();
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [teamLogoUrl, setTeamLogoURL] = useState([]);
  const [gameDate, setGameDate] = useState(moment());
  const [awayTeam, setAwayTeam] = useState();
  const [awayTeamUseTeamLogo, setAwayTeamUseTeamLogo] = useState(false);
  const [homeTeam, setHomeTeam] = useState();
  const [homeTeamUseTeamLogo, setHomeTeamUseTeamLogo] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);

  const drawerBleeding = 56;

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await addDoc(collection(db, "games"), {
      createdAt: serverTimestamp(),
      gameDate: gameDate._d,
      awayTeam: awayTeam,
      awayTeamUseTeamLogo: awayTeamUseTeamLogo,
      awayScore: null,
      homeTeam: homeTeam,
      homeTeamUseTeamLogo: homeTeamUseTeamLogo,
      homeScore: null,
      team: firebaseUser.team,
      assistMakers: [],
      goalScorers: [],
      lvp: null,
      mvp: null,
      redCards: [],
      yellowCards: [],
    });

    setOpen(false);
    toast.success(t("games.messages.gamecreated"));
    fetchGames();
  };
  const fetchGames = async () => {
    let tempTeamGamesArray = [];
    const userData = await getDoc(userRef);
    setFirebaseUser(userData.data());
    if (userData.data().team) {
      const q = query(
        collection(db, "games"),
        where("team", "==", userData.data().team),
        orderBy("gameDate", "asc")
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let tempGameObj = doc.data();
        tempGameObj.id = doc.id;
        tempTeamGamesArray.push(tempGameObj);
      });

      let logoURL = (await getDoc(userData.data().team)).data().imageURL;
      console.log(logoURL);

      setTeamLogoURL(logoURL);
      setGames(tempTeamGamesArray);
      setLoading(false);
    } else {
      toast.warn(t("general.errors.somethingwentwrong"));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 1,
        minHeight: "calc(100vh - 10vh)",
      }}
    >
      <Typography
        sx={{ p: 1, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {t("navmenu.games")}
      </Typography>
      {loading && <Spinner />}
      {!loading && games.length < 1 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <EmptyStateComponent2
            heading={t("games.nogamesyet")}
            caption={t("games.addgames")}
          />
        </Box>
      )}
      {!loading && games.length > 0 && (
        <List sx={{ mb: 6 }}>
          {games.map((game) => (
            <ListItem
              key={game.id}
              sx={{
                backgroundColor: theme.palette.grey.light,
                p: 1,
                my: 1,
                borderRadius: `${theme.shape.borderRadius}px`,
              }}
            >
              <ListItemButton onClick={() => navigate(game.id)}>
                <ListItemText
                  align="center"
                  disableTypography
                  primary={
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* {console.log(game, homeTeamUseTeamLogo)} */}
                          <img
                            style={{ width: "30px" }}
                            src={
                              game.homeTeamUseTeamLogo
                                ? teamLogoUrl
                                : emptyClubLogo
                            }
                            alt="logo"
                          />
                          {game.homeTeam}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: theme.palette.grey.dark,
                            p: 1,
                            borderRadius: `${theme.shape.borderRadius}px`,
                            fontWeight: "bold",
                            minWidth: "75px",
                          }}
                        >
                          {game.homeScore ? game.homeScore : "?"}
                        </Box>
                      </Box>
                      <Divider
                        sx={{
                          "> span": {
                            padding: 0,
                          },
                        }}
                        spacing={0}
                      >
                        <Chip label={convertDate(game.gameDate.toDate())} />
                      </Divider>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            style={{ width: "30px" }}
                            src={
                              game.awayTeamUseTeamLogo
                                ? teamLogoUrl
                                : emptyClubLogo
                            }
                            alt="logo"
                          />
                          {game.awayTeam}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: theme.palette.grey.dark,
                            p: 1,
                            borderRadius: `${theme.shape.borderRadius}px`,
                            fontWeight: "bold",
                            minWidth: "75px",
                          }}
                        >
                          {game.homeScore ? game.awayScore : "?"}
                        </Box>
                      </Box>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      <Fab
        sx={theme.fabStyle}
        color="primary"
        aria-label="add"
        onClick={toggleDrawer(true)}
      >
        <Add />
      </Fab>

      <SwipeableDrawer
        className="swipeableDrawer"
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: open ? -drawerBleeding : 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
              display: "flex",
              p: 2,
              backgroundColor: "#fff",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textAlign: "center",
                flex: "1",
              }}
            >
              {t("games.addgame")}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={toggleDrawer(false)}>
              <Close sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl fullWidth sx={{ ">div": { margin: 1 }, flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box>
                <TextField
                  autoFocus
                  margin="dense"
                  id="home-team"
                  name="home-team"
                  label={t("games.gamedetails.hometeam")}
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHomeTeam(e.target.value)}
                />
                {teamLogoUrl && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("games.gamedetails.useteamlogo")}
                    <Checkbox
                      onChange={(e) => setHomeTeamUseTeamLogo(e.target.checked)}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <TextField
                  autoFocus
                  margin="dense"
                  id="away-team"
                  name="away-team"
                  label={t("games.gamedetails.awayteam")}
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAwayTeam(e.target.value)}
                />
                {teamLogoUrl && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {t("games.gamedetails.useteamlogo")}
                    <Checkbox
                      onChange={(e) => setAwayTeamUseTeamLogo(e.target.checked)}
                    />
                  </Box>
                )}
              </Box>

              {fullScreen && (
                <MobileDatePicker
                  label={t("general.tablecolumns.date")}
                  inputFormat="DD/MM/yyyy"
                  value={gameDate.toDate()}
                  onChange={(e) => setGameDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              {!fullScreen && (
                <DesktopDatePicker
                  label={t("general.tablecolumns.date")}
                  sx={{ margin: 1, padding: 1, backgroundColor: "red" }}
                  inputFormat="DD/MM/yyyy"
                  value={gameDate.toDate()}
                  onChange={(e) => setGameDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </LocalizationProvider>
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            autoFocus
            sx={{ bottom: 0, display: "absolute" }}
          >
            {t("games.creategame")}
          </Button>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default Games;
