import React, { useState } from "react";
import { LocalDrink, Sailing, SportsBar } from "@mui/icons-material";
import {
  Avatar,
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase-config";
import TotalDrinksPerPlayer from "../helper-components/general/TotalDrinksPerPlayer";
import Spinner from "../helper-components/Spinner";
import { HeaderIconTextComponent } from "../Team/MyTeamHeader";
import { useTranslation } from "react-i18next";

const PlayerListItem = (props) => {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [loadingTotalDrinksDrank, setLoadingTotalDrinksDrank] = useState(false);
  const [loadingTotalDrinksBought, setLoadingTotalDrinksBought] =
    useState(false);
  let player = props.player;
  const { user } = useUserAuth();
  const isActiveUser = user.uid === player.id;

  const addDrinkLines = async (amount, type) => {
    type === "bought"
      ? setLoadingTotalDrinksBought(true)
      : setLoadingTotalDrinksDrank(true);
    await addDoc(collection(db, "drink-lines"), {
      amount: amount,
      createdBy: doc(db, "users", user.uid),
      createdFor: doc(db, "users", player.id),
      createdAt: serverTimestamp(),
      type: type,
    });
    type === "bought"
      ? setLoadingTotalDrinksBought(false)
      : setLoadingTotalDrinksDrank(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: isActiveUser
          ? theme.palette.primary.light
          : theme.palette.common.white,
        color: isActiveUser ? theme.palette.common.white : "inherit",
        boxShadow: isActiveUser ? 3 : 0,
      }}
    >
      <ListItem
        disablePadding
        secondaryAction={
          <Box>
            <IconButton onClick={() => addDrinkLines(11, "bought")}>
              {HeaderIconTextComponent(
                <Sailing />,
                null,
                t("drinks.buy") + " 10+1",
                isActiveUser ? true : false
              )}
            </IconButton>
          </Box>
        }
      >
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemText>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flex: 3, display: "flex", alignItems: "center" }}>
                <Avatar
                  key={player.id}
                  src={
                    player.imageURL === null
                      ? "brokenimage.jpg"
                      : player.imageURL
                  }
                  alt={player.name}
                  sx={ player.imageURL === null ? { bgcolor: player.color}: {bgcolor:"none"} }
                  />
                <span style={{ marginLeft: "10px" }}>{player.name}</span>
              </Box>
              <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
                {loadingTotalDrinksDrank ? (
                  <Spinner size={16} align="start" />
                ) : (
                  <TotalDrinksPerPlayer player={player} type={"drank"} />
                )}
              </Box>
              <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
                {loadingTotalDrinksBought ? (
                  <Spinner size={16} align="start" />
                ) : (
                  <TotalDrinksPerPlayer player={player} type={"bought"} />
                )}
              </Box>
            </Box>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Box>
            <IconButton onClick={() => addDrinkLines(1, "drank")}>
              {HeaderIconTextComponent(
                <LocalDrink />,
                null,
                t("drinks.drink") +" 1",
                isActiveUser ? true : false
              )}
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={() => addDrinkLines(1, "bought")}>
              {HeaderIconTextComponent(
                <SportsBar />,
                null,
                t("drinks.buy") + " 1",
                isActiveUser ? true : false
              )}
            </IconButton>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default PlayerListItem;
