import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";

export const fetchAllPossibleFines = async (teamId) => {
  const teamRef = doc(db, "teams", teamId);

  let tempFinesArray = [];
  const q = query(collection(db, "fines"), where("team", "==", teamRef));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    tempFinesArray.push({ id: doc.id, details: doc.data() });
  });
  return tempFinesArray;
};
