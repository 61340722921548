import React from "react";
import EmptyState from "@pluralsight/ps-design-system-emptystate";
import { Box } from "@mui/material";

const EmptyStateComponent = (props) => {
  return (
    <>
      {/* <Box sx={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)", padding:"5rem"}} > */}
      <Box
        sx={{
          position: props.parentComponent ? "flex" : "absolute",
          top: "50%",
          left: "50%",
          transform: props.parentComponent ? "none" : "translate(-50%, -50%)",
          padding: "5rem",
        }}
      >
        {console.log("Empty State Component", props)}
        <EmptyState
          illustration={
            <EmptyState.Illustration
              name={EmptyState.Illustration.names.magnify}
            />
          }
          heading={<EmptyState.Heading>{props.heading}</EmptyState.Heading>}
          caption={<EmptyState.Caption>{props.caption}</EmptyState.Caption>}
          size={EmptyState.sizes.small}
        />
      </Box>
    </>
  );
};

export default EmptyStateComponent;
