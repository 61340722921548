import { React, useState, useEffect } from "react";

import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import Spinner from "../helper-components/Spinner";
import { db } from "../../firebase-config";
import SwipeableViews from "react-swipeable-views";

import { Tabs, Tab, Button } from "@mui/material/";

import Box from "@mui/material/Box";
import { ChevronLeft } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import PlayerHeader from "./PlayerHeader";
import PlayerFinesTab from "./PlayerFinesTab";
import { fetchFinesWithPlayerId } from "../../helperFunctions/GetFinesForPlayer";
import { getTotalDrinksPerPlayer } from "../../helperFunctions/getTotalDrinksPerPlayer";
import PlayerDrinksTab from "./PlayerDrinksTab";
import { fetchAllPossibleFines } from "../../helperFunctions/GetAllPossibleFines";
import PlayerGamesTab from "./PlayerGamesTab";
import { filterGamesPerPlayerAndFilterType, getGamesForTeam } from "../../helperFunctions/GetRelatedGames";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ minHeight: "calc(100vh - 45px)" }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const PlayerDetail = () => {
  //Translations
  const { t } = useTranslation()

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  let { playerId } = useParams();
  const [player, setPlayer] = useState({});
  const [team, setTeam] = useState([]);
  const [fines, setFines] = useState([]);
  const [allPossibleFines, setAllPossibleFines] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const [games, setGames] = useState([]);
  const [rerenderValue, setRerenderValue] = useState(false);
  let relatedGames = [];
  let navbarHeight = "104px";
  const styles = {
    slide: {
      paddingTop: 15,
      minHeight: `calc(100vh - ${navbarHeight} )`,
    },
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const fetchFines = async () => {
    const fines = await fetchFinesWithPlayerId(playerId);
    setFines(fines.fines);
  };
  const fetchAllFines = async (teamId) => {
    const allFines = await fetchAllPossibleFines(teamId);
    setAllPossibleFines(allFines);
  };

  const fetchDrinks = async () => {
    const drinks = await getTotalDrinksPerPlayer(playerId);
    setDrinks(drinks);
  };
  
  const fetchFirebaseUser = async () => {
    setLoading(true)
    const userData = await getDoc(doc(db, "users", playerId));
    setPlayer(userData.data());
    const userTeam = await getDoc(userData.data().team);
    let userTeamObj = userTeam.data();
    userTeamObj.id = userTeam.id;
    setTeam(userTeamObj);
    await fetchAllFines(userTeam.id);
    const games = await getGamesForTeam(userData.data().team)
    relatedGames = filterGamesPerPlayerAndFilterType(playerId, games)
    setGames(games)

    setLoading(false)
  };

  useEffect(() => {
    fetchFirebaseUser();
    fetchFines();
    fetchDrinks();
  }, [rerenderValue]);

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => navigate("/team")}
        sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
      >
        <ChevronLeft />
        {t("navmenu.myteam")}
      </Button>
      {!loading && (

      <PlayerHeader player={player} team={team} fines={fines} drinks={drinks} games={games} relatedGames={filterGamesPerPlayerAndFilterType(playerId, games)} />
      )}

      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={t("navmenu.games")}
              sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
            />
            <Tab
              label={t("navmenu.drinks")}
              sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
            />
            <Tab
              label={t("navmenu.fines")}
              sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
            />
          </Tabs>
        </Box>

        {loading && <Spinner />}
        <SwipeableViews
          enableMouseEvents
          onChangeIndex={handleChangeIndex}
          index={value}
        >
          <TabPanel style={Object.assign({}, styles.slide)}>
            {!loading && value === 0 && (
              <PlayerGamesTab player={player} playerId={playerId} games={games} relatedGames={filterGamesPerPlayerAndFilterType(playerId, games)}/>
            )}
          </TabPanel>
          <TabPanel style={Object.assign({}, styles.slide)}>
            {!loading && value === 1 && (
              <PlayerDrinksTab player={player} drinks={drinks} />
            )}
          </TabPanel>
          <TabPanel style={Object.assign({}, styles.slide)}>
            {!loading && value === 2 && (
              <PlayerFinesTab
                playerId={playerId}
                player={player}
                fines={fines}
                allFines={allPossibleFines}
                callBack={setRerenderValue}
                rerenderValue={rerenderValue}
              />
            )}
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  );
};

export default PlayerDetail;
