import React, { useState } from "react";
import {
  AssistWalker,
  EmojiEvents,
  Gavel,
  SportsBar,
  LocalDrink,
} from "@mui/icons-material";
import { Avatar, Box, Typography, useTheme } from "@mui/material";

import { HeaderIconTextComponent } from "./MyTeamHeader";

const PlayerHeader = (props) => {
  const theme = useTheme();

  let player = props.player;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        p: 2,
        mt: 2,
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Avatar
          key={player.id}
          onClick={() => {
            if (player.imageURL) {
              window.location.href = player.imageURL;
            }
          }}
          src={player.imageURL === null ? "brokenimage.jpg" : player.imageURL}
          alt={player.name}
          sx={
            player.imageURL === null
              ? { bgcolor: player.color, width: 80, height: 80 }
              : { bgcolor: "none", width: 80, height: 80 }
          }
        />
        <Box>
          <Typography variant="h5">{player.name}</Typography>
          <Typography variant="caption" color={"grey"}>
            {props.team.name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", color: "grey", mt: 2, gap: "5%" }}>
        {HeaderIconTextComponent(
          <EmojiEvents />,
          props.relatedGames.mvpGames.length
        )}
        {HeaderIconTextComponent(
          <AssistWalker />,
          props.relatedGames.lvpGames.length
        )}
        |
        {props.drinks.drinksArray &&
          props.drinks.drinksArray.length > 0 &&
          HeaderIconTextComponent(
            <SportsBar />,
            props.drinks.drinksArray
              .filter((drinkLine) => drinkLine.details.type === "bought")
              .map((boughtDrink) => boughtDrink.details.amount)
              .reduce((partialSum, a) => partialSum + a, 0)
          )}
        {props.drinks.drinksArray &&
          props.drinks.drinksArray.length > 0 &&
          HeaderIconTextComponent(
            <LocalDrink />,
            props.drinks.drinksArray.filter(
              (drinkLine) => drinkLine.details.type === "drank"
            ).length
          )}
        |{HeaderIconTextComponent(<Gavel />, props.fines.length)}
      </Box>
    </Box>
  );
};

export default PlayerHeader;
