import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const FineStats = (props) => {
  //Translations
  const { t } = useTranslation();

  const { teamPlayers, fines, fineLines } = props;
  const [sortedBy, setSortedBy] = useState("playerName");
  const [sortOrder, setSortOrder] = useState("asc");
  // Calculate fine statistics for each player
  const playerStatistics = teamPlayers.map((player) => {
    const { id, name } = player;
    const playerFineLines = fineLines.filter(
      (fineLine) => fineLine.player === id
    );
    const totalFineAmount = playerFineLines.reduce(
      (sum, fineLine) =>
        sum + fines.find((fine) => fine.id === fineLine.fine)?.amount || 0,
      0
    );

    return {
      playerId: id,
      playerName: name,
      playerImageURL: player.imageURL,
      totalFineLines: playerFineLines.length,
      totalFineAmount,
    };
  });

  // Sort player statistics based on selected column
  const handleSort = (property) => {
    const isAscending = sortedBy === property && sortOrder === "asc";
    setSortedBy(property);
    setSortOrder(isAscending ? "desc" : "asc");
  };

  const sortedPlayerStatistics = playerStatistics.sort((a, b) => {
    const isAscending = sortOrder === "asc" ? 1 : -1;
    if (a[sortedBy] < b[sortedBy]) {
      return -1 * isAscending;
    }
    if (a[sortedBy] > b[sortedBy]) {
      return 1 * isAscending;
    }
    return 0;
  });

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "100vw", overflowX: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("general.tablecolumns.player")}</TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalFineLines"}
                direction={sortOrder}
                onClick={() => handleSort("totalFineLines")}
              >
                {t("general.tablecolumns.finesreceived")}
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none">
              <TableSortLabel
                active={sortedBy === "totalFineAmount"}
                direction={sortOrder}
                onClick={() => handleSort("totalFineAmount")}
              >
                {t("general.tablecolumns.amountissued")}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPlayerStatistics.map((player) => (
            <TableRow key={player.playerId}>
              <TableCell>
                {player.playerImageURL == null && player.playerName}
                {player.playerImageURL != null && (
                  <Box>
                    <Avatar
                      key={player.playerId}
                      src={
                        player.playerImageURL != null
                          ? player.playerImageURL
                          : "genericUserImageURL"
                      }
                      alt={player.playerName}
                      sx={
                        player.imageURL === null
                          ? { bgcolor: player.color }
                          : { bgcolor: "none" }
                      }
                    />
                    <Box>{player.playerName}</Box>
                  </Box>
                )}
              </TableCell>
              <TableCell padding="none" align="center">
                {player.totalFineLines}
              </TableCell>
              <TableCell padding="none" align="center">
                {t("intlCurrencyWithOptions", { val: player.totalFineAmount })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FineStats;
