import { useState, useEffect } from "react";
import { collection, doc, getDocs, query, where } from "firebase/firestore";

import { db } from "../../firebase-config";
import Top3Card from "./Top3Card";
import { Box } from "@mui/system";
import { Skeleton, Typography, useTheme } from "@mui/material";

export default function Top3(props) {
  const { players, type, games } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [top3, setTop3] = useState([]);
  const [teamTotal, setTeamTotal] = useState(0);

  useEffect(() => {
    async function fetchData() {
      let tempArray = [];
      let playerDataQuery;
      let querySnapshot;
      let tempTeamTotal = 0;

      if (
        type === "goalScorers" ||
        type === "assistMakers" ||
        type === "redCards" ||
        type === "yellowCards"
      ) {
        players.map((player) => {
          // Use the reduce function to count occurrences of playerIdToCount in all games.
          const count = games.reduce((total, game) => {
            const occurrencesInGame = game[type].reduce(
              (gameTotal, scorer) =>
                scorer === player.id ? gameTotal + 1 : gameTotal,
              0
            );
            tempTeamTotal += occurrencesInGame;
            return total + occurrencesInGame;
          }, 0);

          tempArray.push({ player: player, result: count });
        });
        setTeamTotal(tempTeamTotal);
      } else if (type === "drinksBought") {
        // Use Promise.all to wait for all the asynchronous operations to complete
        await Promise.all(
          players.map(async (player) => {
            playerDataQuery = query(
              collection(db, "drink-lines"),
              where("createdFor", "==", doc(db, "users", player.id)),
              where("type", "==", "bought")
            );
            let total = 0;
            querySnapshot = await getDocs(playerDataQuery);
            querySnapshot.forEach((doc) => {
              total += doc.data().amount;
            });
            tempArray.push({ player: player, result: total });
            tempTeamTotal += total ;
          })
        );
        setTeamTotal(tempTeamTotal);
      } else if (type === "fines") {
        // Use Promise.all to wait for all the asynchronous operations to complete
        await Promise.all(
          players.map(async (player) => {
            playerDataQuery = query(
              collection(db, "fine-lines"),
              where("player", "==", player.id)
            );
            let total = 0;
            querySnapshot = await getDocs(playerDataQuery);
            querySnapshot.forEach((doc) => {
              total += 1;
            });
            tempArray.push({ player: player, result: total });
            tempTeamTotal += total 
          })
        );
        setTeamTotal(tempTeamTotal)

      }

      // console.log(`setting top3 for ${type} with temparray`, tempArray);
      setTop3(tempArray.sort((a, b) => b.result - a.result));

      setLoading(false);
    }

    fetchData();
  }, [players, type, games]);

  return (
    <Box>
      <Typography
        sx={{ p: 2, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {props.title} {teamTotal > 0 && `(${teamTotal})`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: "0 1rem",
          overflowX: "scroll",
          width: { xs: "100vw", sm: "calc(100vw - 310px)" },
        }}
      >
        {loading &&
          players.map((player) => {
            return (
              <Skeleton
                key={player.id}
                sx={{
                  mx: 1,
                  flex: " 0 0 auto",
                  width: 130,
                  height: 130,
                }}
                variant="rounded"
              />
            );
          })}
        {!loading &&
          top3?.length > 0 &&
          top3.map((player) => (
            <Top3Card
              key={player.player.id}
              player={player.player}
              result={player.result}
            />
          ))}
      </Box>
    </Box>
  );
}
