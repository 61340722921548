import { React, useState, useEffect } from "react";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase-config";

import Spinner from "../helper-components/Spinner";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  FormControl,
  Fab,
  IconButton,
  Typography,
  SwipeableDrawer,
} from "@mui/material";
import { Add, Close, ContentCopy, Edit } from "@mui/icons-material";

import { toast } from "react-toastify";
import { Box } from "@mui/system";
import MyTeamHeader from "./MyTeamHeader";
import PlayersList from "./PlayersList";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";
import { useTranslation } from "react-i18next";
import { randomHexColor } from "../../helperFunctions/randomHexColor";
import { NavLink } from "react-router-dom";

function MyTeam() {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);

  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState();
  const [team, setTeam] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [teamCode, setTeamCode] = useState([]);
  const [playerName, setPlayerName] = useState("");

  // Create Team Modal
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false);
  const [openLinkTeamModal, setOpenLinkTeamModal] = useState(false);
  const handleClickOpenTeamModal = () => {
    setOpenCreateTeamModal(true);
  };

  const handleCloseTeamModal = () => {
    setOpenCreateTeamModal(false);
  };

  // Invite Players Modal
  const toggleInvitePlayersDrawer = (newOpen) => () => {
    setOpenInvitePlayersModal(newOpen);
  };
  const drawerBleeding = 56;

  const [openInvitePlayersModal, setOpenInvitePlayersModal] = useState(false);

  const fetchUserdata = async () => {
    const userData = await getDoc(userRef);
    setFirebaseUser(userData.data());

    if (userData.data().team !== null) {
      const userTeam = await getDoc(userData.data().team);
      if (userTeam.data() == null) {
        toast.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));
      }
      let userTeamObj = userTeam.data();
      userTeamObj.id = userTeam.id;
      setTeam(userTeamObj);
      let tempTeamPlayersArray = await getTeamPlayers(userData.data().team);
      setTeamPlayers(tempTeamPlayersArray);
      setLoading(false);
    } else {
      setLoading(false);
      // toast.error("You're not linked to a team yet");
    }
  };

  const handleSubmit = async () => {
    const teamRef = await addDoc(collection(db, "teams"), {
      name: teamName,
    });
    if (teamRef.id) {
      await updateDoc(doc(db, "users", user.uid), {
        team: teamRef,
        role: "teamAdmin",
      });
      const teamData = await getDoc(doc(db, "teams", teamRef.id));
      setTeam(teamData.data());
    }
    toast.success(t("team.messages.teamcreated"));
    handleCloseTeamModal();
  };

  const handleAddPlayerSubmit = async () => {
    await addDoc(collection(db, "users"), {
      name: playerName,
      authProvider: "none",
      email: null,
      lang: "nl",
      team: firebaseUser.team,
      role: null,
      imageURL: null,
      color: randomHexColor(),
      uid: null,
    });
    toast.success(t("team.messages.playercreated"));
    handleCloseTeamModal();
  };

  const handleLinkTeamWithCode = async () => {
    try {
      const teamRef = doc(db, "teams", teamCode);
      const docSnap = await getDoc(teamRef);

      if (docSnap.exists()) {
        try {
          await updateDoc(doc(db, "users", user.uid), {
            team: docSnap.ref,
          });
          toast.success(
            t("team.messages.addedyourselftoteam", {
              teamName: docSnap.data().name,
            })
          );
          fetchUserdata();
        } catch (error) {
          console.log(error);
          toast.error(
            t("team.messages.addingyourselferror", {
              teamName: docSnap.data().name,
            })
          );
        }
      } else {
        toast.error(
          "Something went wrong. Are you sure that is a correct team code?"
        );
        toast.error(t("team.messages.errorteamcode"));
        setOpenLinkTeamModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error: ${error}`);
    }
    setOpenLinkTeamModal(false);
  };

  useEffect(() => {
    fetchUserdata();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 1,
        minHeight: "calc(100vh - 10vh)",
      }}
    >
      <Typography
        sx={{
          p: 1,
          color: theme.palette.primary.main,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {t("navmenu.myteam")}
        <NavLink
          to={`edit`}
          style={() => {
            return {
              color: "inherit"
            };
          }}
        >
          <Edit />
        </NavLink>
      </Typography>
      {loading && <Spinner />}
      {!loading && (true == false || team.length === 0) && (
        <Box>
          <Box sx={{}}>
            <Box>{t("team.noteamlinkedyet+create")}</Box>
            <Button variant="contained" onClick={handleClickOpenTeamModal}>
              {t("team.createteam")}
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>{t("team.orlinkaccounttoexistingteam")}</Box>
            <Button
              variant="contained"
              onClick={() => setOpenLinkTeamModal(true)}
            >
              {t("team.linkaccounttoteam")}
            </Button>
          </Box>
          <Dialog
            open={openCreateTeamModal}
            onClose={handleCloseTeamModal}
            fullScreen={fullScreen}
          >
            <DialogTitle>{t("team.createteam")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("team.filldetailscreateteam")}
              </DialogContentText>
              {/* TODO: add team Logo */}
              <FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={t("team.teamname")}
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setTeamName(e.target.value)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTeamModal}>
                {t("general.cancel")}
              </Button>
              <Button variant="contained" onClick={handleSubmit} autoFocus>
                {t("team.createteam")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openLinkTeamModal}
            onClose={() => {
              setOpenLinkTeamModal(false);
            }}
            fullScreen={fullScreen}
          >
            <DialogTitle>{t("team.linkteam")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("team.enterteamlinkfromadmin")}
              </DialogContentText>
              <FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={t("team.teamcode")}
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setTeamCode(e.target.value)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenLinkTeamModal(false);
                }}
              >
                {t("general.cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={handleLinkTeamWithCode}
                autoFocus
              >
                {t("team.linkteam")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      {teamPlayers.length > 0 && (
        <MyTeamHeader team={team} teamPlayers={teamPlayers} />
      )}
      {teamPlayers.length > 0 && (
        <PlayersList team={team} teamPlayers={teamPlayers} />
      )}
      {!loading && team.id && (
        <Fab
          sx={theme.fabStyle}
          color="primary"
          aria-label="add"
          onClick={() => setOpenInvitePlayersModal(true)}
        >
          <Add />
        </Fab>
      )}

      {/* invite players */}
      <SwipeableDrawer
        className="swipeableDrawer"
        anchor="bottom"
        open={openInvitePlayersModal}
        onClose={toggleInvitePlayersDrawer(false)}
        onOpen={toggleInvitePlayersDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {openInvitePlayersModal && (
          <Box
            sx={{
              position: "absolute",
              top: openInvitePlayersModal ? -drawerBleeding : 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
              display: "flex",
              p: 2,
              backgroundColor: "#fff",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textAlign: "center",
                flex: "1",
              }}
            >
              {t("team.invite.addplayers")}
            </Typography>
            <IconButton
              sx={{ p: 0 }}
              onClick={toggleInvitePlayersDrawer(false)}
            >
              <Close sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("team.invite.invitetoteam")}
          </Typography>
          {t("team.invite.copylinkandsend")}
          <Box>
            {window.location.origin + "/signup?teamId=" + team.id}
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  window.location.origin + "/signup?teamId=" + team.id
                );
                toast.success("Code copied");
              }}
            >
              <ContentCopy />
            </Button>
          </Box>
          <hr />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("team.invite.addplayerwithoutinvitation")}
          </Typography>
          <Box>
            <FormControl fullWidth sx={{ ">div": { margin: 1 }, flex: 1 }}>
              <TextField
                autoFocus
                margin="dense"
                id="player-name"
                name="player-name"
                label={t("team.invite.playername")}
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setPlayerName(e.target.value)}
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              onClick={handleAddPlayerSubmit}
              autoFocus
              sx={{ bottom: 0, display: "absolute" }}
            >
              {t("team.invite.addplayer")}
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MyTeam;
