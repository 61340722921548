import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";

export const getGamesForTeam = async (teamRef) => {
  let tempTeamGamesArray = [];

  const q = query(
    collection(db, "games"),
    where("team", "==", teamRef),
    orderBy("gameDate", "asc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let tempGameObj = doc.data();
    tempGameObj.id = doc.id;
    tempTeamGamesArray.push(tempGameObj);
  });
  return tempTeamGamesArray;
};

export const filterGamesPerPlayerAndFilterType = (playerId, allGames) => {
  let relevantGames = [];
  let mvpGames = [];
  let lvpGames = [];
  let redCardGames = [];
  let yellowCardGames = [];
  let goalScorerGames = [];
  let assistMakerGames = [];
  allGames.map((game) => {
    if (
      game.mvp === playerId ||
      game.lvp === playerId ||
      game.redCards.includes(playerId) ||
      game.yellowCards.includes(playerId) ||
      game.goalScorers.includes(playerId) ||
      game.assistMakers.includes(playerId)
    ) {
      relevantGames.push(game);
      if (game.mvp === playerId) {
        mvpGames.push(game);
      }
      if (game.lvp === playerId) {
        lvpGames.push(game);
      }
      if (game.redCards.includes(playerId)) {
        redCardGames.push(game);
      }
      if (game.yellowCards.includes(playerId)) {
        yellowCardGames.push(game);
      }
      if (game.goalScorers.includes(playerId)) {
        goalScorerGames.push(game);
      }
      if (game.assistMakers.includes(playerId)) {
        assistMakerGames.push(game);
      }
    }
  });
  return {
    relevantGames: relevantGames,
    mvpGames: mvpGames,
    lvpGames: lvpGames,
    redCardGames: redCardGames,
    yellowCardGames: yellowCardGames,
    goalScorerGames: goalScorerGames,
    assistMakerGames: assistMakerGames,
  };
};
