import { React } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import ResponsiveAppBar from "./Appbar";
import Spinner from "./helper-components/Spinner";

import { ToastContainer, } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const ProtectedRoute = (props) => {
  // console.log('props', props)
  const { user } = useUserAuth();

  const prevLocation = useLocation();

  if (!user) {
    if (prevLocation.pathname !== "/") {
      return <Navigate to={`/login?redirect=${prevLocation.pathname}`} />;
    } else {
      return <Navigate to={`/login`} />;
    }
  } else if (Object.keys(user).length !== 0) {
    // const userRef = doc(db, "users", user.uid);
    // const fetchUserdata = async (userRef) => {
    //   const docSnap = await getDoc(userRef);
    //   i18n.changeLanguage(docSnap.data().lang);
    // };
    // fetchUserdata(userRef)
  }
  // TODO: fix getting language from user

  return (
    <>
      {Object.keys(user).length === 0 && <Spinner />}

      {Object.keys(user).length !== 0 && (
        <ResponsiveAppBar children={props.children} backButton={props.backButton}>
          {props.children}
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss = {false}
            draggable
            pauseOnHover = {false}
            theme="colored"
          />
        </ResponsiveAppBar>
      )}
    </>
  );
};

export default ProtectedRoute;
