import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/userAuthContext";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Container
} from "@mui/material";

const Login = () => {
  //Translations
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  let sp = new URLSearchParams(location.search);
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      if (sp.has("redirect") && sp.get("redirect") !== "/") {
        console.log(sp.get("redirect"));
        navigate(`${sp.get("redirect")}`);
      } else {
        navigate("/home");
      }
    } catch (err) {
      setError(err.message);
    }
  };
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();

    try {
      await googleSignIn();
      if (sp.has("redirect") && sp.get("redirect") !== "/") {
        navigate(`${sp.get("redirect")}`);
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    document.body.classList.add("color-page");
    return () => {
      document.body.classList.remove("color-page");
    };
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Typography sx={{ color: theme.palette.common.white, mb: 10 }} variant="h4">
      {t("general.appname")}
      </Typography>
      <Box>
        <Box
          sx={{
            p: 4,
            border: "1px solid #dfdfdf",
            borderRadius: `${theme.shape.borderRadius}px`,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <h2>{t("accountdetails.login")}</h2>
          {error && <Alert severity="error">{error}</Alert>}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ color: theme.palette.common.white }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("accountdetails.email")}
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("accountdetails.password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" type="Submit" fullWidth sx={{ mt: 2 }}>
            {t("accountdetails.login")}
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <GoogleButton
              className="g-btn"
              type="dark"
              onClick={handleGoogleSignIn}
              label= {t("accountdetails.googlesignin")}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: 4,
            marginTop: 2,
            textAlign: "center",
            border: "1px solid #dfdfdf",
            backgroundColor: "#fff",
          }}
        >
          {t("accountdetails.noaccount")}<Link to="/signup">{t("accountdetails.signup")}</Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
