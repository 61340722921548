import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, useTheme, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import "react-image-crop/dist/ReactCrop.css";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "../../firebase-config";
import UploadImage from "../helper-components/UploadImage";
import { useUserAuth } from "../../context/userAuthContext";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";
import { toast } from "react-toastify";
import Spinner from "../helper-components/Spinner";

const MyTeamEdit = () => {
  //Translations
  const { t } = useTranslation();

  const { user } = useUserAuth();

  const theme = useTheme();
  const userRef = doc(db, "users", user.uid);

  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState({});
  const [teamRef, setTeamRef] = useState();

  const fetchTeamForPlayer = async () => {
    setLoading(true)
    const userData = await getDoc(userRef);

    const userTeam = await getDoc(userData.data().team);
    if (userTeam.data() == null) {
      toast.warn(t("general.errors.teamlinkedtoaccountdoesnotexist"));
    }
    let userTeamObj = userTeam.data();
    userTeamObj.id = userTeam.id;
    setTeamRef(doc(db, "teams", userTeam.id));
    setTeam(userTeamObj);
    setLoading(false)
  };

  useEffect(() => {
    fetchTeamForPlayer();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 2,
        minHeight: "calc(100vh - 10vh)",
      }}
    >
      {loading && (<Spinner></Spinner>)}
      {!loading && (
        <Box>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("team.edit.editteam", {
              teamName: team.name,
            })}
          </Typography>
          <Typography sx={{ color: theme.palette.headers.subheader, py: 1 }}>
            {t("team.edit.teamlogo")}
          </Typography>
          <UploadImage
            path={"logos"}
            name={team.id}
            toUpdateRef={teamRef}
            toUpdateAttr={"imageURL"}
            currentImage={team?.imageURL?.length > 0 ? team.imageURL : null}
          ></UploadImage>
        </Box>
      )}
    </Box>
  );
};

export default MyTeamEdit;
