import React from "react";
import { Gavel, People, SportsBar } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Typography, useTheme } from "@mui/material";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const HeaderIconTextComponent = (
  icon,
  value,
  text,
  inverted = false
) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2%",
          color: inverted
            ? theme.palette.common.white
            : theme.palette.grey[600],
        }}
      >
        {icon}
        {value}
      </Box>
      {text ? (
        <Typography
          variant="caption"
          sx={{
            fontWeight: inverted ? 400 : 600,
            color: inverted ? theme.palette.common.white : "inherit",
          }}
        >
          {text}
        </Typography>
      ) : null}
    </Box>
  );
};
const MyTeamHeader = (props) => {
  //Translations
  const { t } = useTranslation();

  const [finesIssuedAmount, setFinesIssuedAmount] = useState(0);
  const [drinksBoughtAmount, setDrinksBoughtAmount] = useState(0);

  const fetchTotalFineAmountByTeamPlayers = async (players) => {
    let totalFineAmount = 0;
    players.forEach(async (teamPlayer) => {
      const q = query(
        collection(db, "fine-lines"),
        where("player", "==", teamPlayer.id)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        totalFineAmount++;
      });
      setFinesIssuedAmount(totalFineAmount);
    });
  };
  const fetchTotalDrinksAmountByTeamPlayers = async (players) => {
    let totalDrinksAmount = 0;
    players.forEach(async (teamPlayer) => {
      let playerRef = doc(db, "users", teamPlayer.id);
      const q = query(
        collection(db, "drink-lines"),
        where("createdFor", "==", playerRef),
        where("type", "==", "bought")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        totalDrinksAmount = totalDrinksAmount + doc.data().amount;
      });
      setDrinksBoughtAmount(totalDrinksAmount);
    });
  };
  useEffect(() => {
    fetchTotalFineAmountByTeamPlayers(props.teamPlayers);
    fetchTotalDrinksAmountByTeamPlayers(props.teamPlayers);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        p: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="h5">{props.team.name}</Typography>
      <Typography variant="caption" color={"grey"}>
        {props.team.id}
      </Typography>
      <AvatarGroup
        max={4}
        total={props.teamPlayers.length}
        sx={{ justifyContent: "center", mt: 2 }}
      >
        {props.teamPlayers.map((player) => {
          return (
            <Avatar
              key={player.id}
              src={
                player.imageURL === null ? "brokenimage.jpg" : player.imageURL
              }
              alt={player.name}
              sx={ player.imageURL === null ? { bgcolor: player.color}: {bgcolor:"none"} }
            />
          );
        })}
      </AvatarGroup>
      <Box sx={{ display: "flex", color: "grey", mt: 2, gap: "10%" }}>
        {HeaderIconTextComponent(
          <People />,
          props.teamPlayers.length,
          t("team.teamheader.totalplayers")
        )}
        {HeaderIconTextComponent(
          <SportsBar />,
          drinksBoughtAmount,
          t("team.teamheader.beersbought")
        )}
        {HeaderIconTextComponent(<Gavel />, finesIssuedAmount, t("team.teamheader.finesissued"))}
      </Box>
    </Box>
  );
};

export default MyTeamHeader;
