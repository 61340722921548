import React, { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase-config";
import { toast } from "react-toastify";
import Spinner from "../helper-components/Spinner";
import {
  useTheme,
  Box,
  List,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import { useTranslation } from "react-i18next";
import PlayerListItem from "./PlayerListItem";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";
import { LocalDrink, SportsBar } from "@mui/icons-material";

const Drinks = () => {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();
  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTeamForPlayer = async () => {
    const userData = await getDoc(userRef);

    if (userData.data().team !== null) {
      let tempTeamPlayersArray = await getTeamPlayers(userData.data().team);
      setTeamPlayers(tempTeamPlayersArray);
      setLoading(false);
    } else {
      toast.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));
    }
  };

  useEffect(() => {
    fetchTeamForPlayer();
  }, []);

  return (
    <Box sx={{ backgroundColor: theme.palette.common.white }}>
      <Typography
        sx={{ p: 2, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {t("navmenu.drinks")}
      </Typography>
      {loading && <Spinner />}
      {!loading && teamPlayers.length < 1 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <EmptyStateComponent2 heading={t("general.errors.noplayersinteam")} />
        </Box>
      )}
      {!loading && teamPlayers.length > 0 && (
        <List>
          <ListItem dense>
            <ListItemText>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    flex: 3,
                  }}
                ></Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LocalDrink />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SportsBar />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                  }}
                ></Box>
              </Box>
            </ListItemText>
          </ListItem>
          {teamPlayers.map((player) => (
            <PlayerListItem key={player.id} player={player} />
          ))}
        </List>
      )}
    </Box>
  );
};

export default Drinks;
