import { React, useState, useEffect } from "react";

import {
  doc,
  getDoc,
  getDocs,
  collection,
  orderBy,
  where,
  query,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import Spinner from "../helper-components/Spinner";
import { db } from "../../firebase-config";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";

import { Tabs, Tab, Button } from "@mui/material/";

import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { useUserAuth } from "../../context/userAuthContext";
import FineHistory from "./FineDetailHistoryTab";
import { ChevronLeft } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import FineDetailDetailTab from "./FineDetailDetailTab";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ minHeight: "calc(100vh - 45px)" }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const FineDetail = () => {
  //Translations
  const { t } = useTranslation();

  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [fineDetails, setFineDetails] = useState({});
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [fineHistory, setFineHistory] = useState([]);
  const [rerenderValue, setRerenderValue] = useState(false);

  let { fineId } = useParams();
  const navigate = useNavigate();

  let amountToBePaid = 0;
  let amountPaid = 0;

  let navbarHeight = "104px";
  const styles = {
    slide: {
      paddingTop: 15,
      minHeight: `calc(100vh - ${navbarHeight} )`,
    },
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const fetchFineDetails = async () => {
    const fineData = await getDoc(doc(db, "fines", fineId));
    setFineDetails(fineData.data());

    setLoading(false);
  };
  const fetchUserdata = async () => {
    const userData = await getDoc(userRef);
    if (userData.data().team) {
      const userTeam = await getDoc(userData.data().team);
      if (userTeam.data() == null) {
        toast.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));
      }
      let tempTeamPlayersArray = await getTeamPlayers(userData.data().team);
      setTeamPlayers(tempTeamPlayersArray);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  async function fetchFineLinesForFine(fineID) {
    let tempFineObj = [];
    const q = query(
      collection(db, "fine-lines"),
      where("fine", "==", fineID),
      orderBy("created", "desc")
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      tempFineObj.push({ id: doc.id, details: doc.data() });
    });
    setLoading(false);
    setFineHistory(tempFineObj);
  }

  fineHistory.forEach((fineLine) => {
    fineLine.details.paid ? amountPaid++ : amountToBePaid++;
  });

  useEffect(() => {
    fetchFineDetails();
    fetchUserdata();
    fetchFineLinesForFine(fineId);
  }, [rerenderValue]);

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => navigate("/fines")}
        sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
      >
        <ChevronLeft />
        {t("general.navigation.back")}
      </Button>
      {!loading && fineDetails === undefined && (
        <EmptyStateComponent2
          heading={"No fine found"}
          caption={
            <Button variant="contained" onClick={() => navigate("/fines")}>
             {t("general.navigation.backtooverview")}
            </Button>
          }
        />
      )}
      {fineDetails !== undefined && (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t("fines.finedetails.finedetail")}
                sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
              />
              <Tab
                label={t("fines.finedetails.finehistory")}
                sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
              />
            </Tabs>
          </Box>

          <SwipeableViews
            enableMouseEvents
            onChangeIndex={handleChangeIndex}
            index={value}
          >
            <TabPanel style={Object.assign({}, styles.slide)}>
              {loading && <Spinner />}
              {!loading && value === 0 && (
                <FineDetailDetailTab
                  fineDetails={fineDetails}
                  teamPlayers={teamPlayers}
                  fineId={fineId}
                  amounts={{
                    amountPaid: amountPaid * fineDetails.amount,
                    amountToBePaid: amountToBePaid * fineDetails.amount,
                  }}
                  callBack={setRerenderValue}
                  rerenderValue={rerenderValue}
                />
              )}
            </TabPanel>
            <TabPanel style={Object.assign({}, styles.slide)}>
              {!loading && value === 1 && (
                <FineHistory
                  fine={fineDetails}
                  fineId={fineId}
                  teamPlayers={teamPlayers}
                  fineHistory={fineHistory}
                  callBack={setRerenderValue}
                  rerenderValue={rerenderValue}
                />
              )}
            </TabPanel>
          </SwipeableViews>
        </Box>
      )}
    </div>
  );
};

export default FineDetail;
