import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase-config';
import Spinner from '../Spinner';


const TotalFineAmountPerPlayer = (props) => {
    const [loading, setLoading] = useState(true)
    const [amount, setAmount] = useState(0)

    async function getAmountOfFinesForPlayer(playerId) {
        let totalAmountOfFines = 0;
        const q = query(
          collection(db, "fine-lines"),
          where("player", "==", playerId)
        );
      
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          totalAmountOfFines++;
        });
        setLoading(false)
        setAmount(totalAmountOfFines)
      }

    useEffect(() => {
        getAmountOfFinesForPlayer(props.player.id);
      }, []);
    return (
        <div>
            {loading && (<Spinner /> )}
            {!loading && (amount)}
        </div>
    );
};

export default TotalFineAmountPerPlayer;