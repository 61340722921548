import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import {
  query,
  getDocs,
  collection,
  where,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";

import { auth } from "../firebase-config";
import { db } from "../firebase-config";
import { randomHexColor } from "../helperFunctions/randomHexColor";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  async function signUp(email, password, nickName, teamId) {
    let res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await updateProfile(auth.currentUser, { displayName: nickName }).catch(
      (err) => console.log(err)
    );
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    let teamRef = null;
    if (teamId !== null) {
      teamRef = doc(db, "teams/" + teamId);
    }
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "user&pw",
        email: user.email,
        lang: "nl",
        team: teamRef,
        role: null,
        imageURL: user.photoURL,
        color: randomHexColor(),
      });
    }
    return res;
  }
  function logOut() {
    return signOut(auth);
  }
  async function googleSignIn(teamId) {
    const googleAuthProvider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, googleAuthProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    let teamRef = null;
    if (teamId !== null) {
      teamRef = doc(db, "teams/" + teamId);
    }
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "Google SSO",
        email: user.email,
        lang: "nl",
        team: teamRef,
        role: null,
        imageURL: user.photoURL,
        color: randomHexColor(),
      });
    }
    return res;
  }

  const getFirebaseUser = async () => {
    const userRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userRef);
    console.log(docSnap.data());
    return docSnap.data();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn, getFirebaseUser }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
