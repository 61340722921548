import { React, useState, useEffect } from "react";

import {
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { Box } from "@mui/system";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import { convertDate } from "../../helperFunctions/convertDate";
import { toggleFinePaid } from "../../helperFunctions/toggleFinePaid";
import { useTranslation } from "react-i18next";



const FineHistory = (props) => {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();
  let fineHistory = props.fineHistory;

  function getPlayerNameFromTeamPlayersArrayById(playerId) {
    let playerObj = props.teamPlayers.find((player) => player.id === playerId);
    return playerObj;
  }

  return (
    <Box>
      {fineHistory.length < 1 && (
        <Box sx={{ backgroundColor: theme.palette.common.white, p: 0 }}>
          <EmptyStateComponent2
            heading={t("fines.finehistory.nobodyfinedyet")}
            caption={t("fines.finehistory.issuefinetoseehere")}
            parentComponent={true}
          />
        </Box>
      )}
      {fineHistory.length > 0 && (
        <Box sx={{ backgroundColor: theme.palette.common.white, p: 0 }}>
          <TableContainer>
            <Table aria-label="simple table" sx={{ "td, th": { border: 0 } }}>
              <caption># {fineHistory.length}</caption>

              <TableHead>
                <TableRow sx={{ color: theme.palette.text.secondary }}>
                  <TableCell sx={{ paddingY: 0, color: "inherit" }}>
                    {t("general.tablecolumns.date")}
                  </TableCell>
                  <TableCell
                    sx={{ paddingY: 0, color: "inherit" }}
                    align="center"
                  >
                    {t("general.tablecolumns.player")}
                  </TableCell>
                  <TableCell
                    sx={{ paddingY: 0, color: "inherit" }}
                    align="right"
                  >
                     {t("general.tablecolumns.paid")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fineHistory.map((fineLine) => (
                  <TableRow key={fineLine.id}>
                    <TableCell>
                      {convertDate(fineLine.details.created.toDate())}
                    </TableCell>
                    <TableCell align="center"  sx={{p:0}}>
                      {
                        getPlayerNameFromTeamPlayersArrayById(
                          fineLine.details.player
                        ).name
                      }
                    </TableCell>
                    <TableCell align="right" sx={{p:0}}>
                      {fineLine.details.paid ? (
                        <span style={{ color: "green" }}> {t("fines.finehistory.paid")}</span>
                      ) : (
                        <span style={{ color: "red" }}>{t("fines.finehistory.notpaid")}</span>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          toggleFinePaid(fineLine.id, fineLine.details.paid);
                          props.callBack(!props.rerenderValue);
                        }}
                      >
                        {fineLine.details.paid ? t("fines.finehistory.unpay") : t("fines.finehistory.pay")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default FineHistory;
