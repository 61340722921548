import { collection, doc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase-config";
import Spinner from "../Spinner";

const TotalDrinksPerPlayer = (props) => {
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(0);

  async function getTotalDrinksPerPlayer(playerId) {
    let playerRef = doc(db, "users", playerId);
    let totalDrinks = 0;
    const q = query(
      collection(db, "drink-lines"),
      where("createdFor", "==", playerRef),
      where("type", "==", props.type)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      totalDrinks = totalDrinks + doc.data().amount;
    });
    setLoading(false);
    setAmount(totalDrinks);
  }

  useEffect(() => {
    getTotalDrinksPerPlayer(props.player.id);
  }, []);
  return (
    <div>
      {loading && <Spinner size={16} />}
      {!loading && amount}
    </div>
  );
};

export default TotalDrinksPerPlayer;
