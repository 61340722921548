import {
  Add,
  AssistWalker,
  ChevronLeft,
  Close,
  Edit,
  EmojiEvents,
  SportsSoccer,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase-config";
import CardIcon from "../helper-components/icons/CardIcon";
import SoccerBoot from "../helper-components/icons/SoccerBoot";
import Spinner from "../helper-components/Spinner";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";
import { useTranslation } from "react-i18next";

const GameDetail = () => {
  //Translations
  const { t } = useTranslation();

  const { user } = useUserAuth();
  let { gameId } = useParams();
  const theme = useTheme();

  const userRef = doc(db, "users", user.uid);
  const gameRef = doc(db, "games", gameId);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [gameDetails, setGameDetails] = useState({});
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  const [openedDrawer, setOpenedDrawer] = useState();
  const [openedBoxTitle, setOpenedBoxTitle] = useState();
  const [formValues, setFormValues] = useState({
    homeScore: null,
    awayScore: null,
  });
  const [open, setOpen] = useState(false);
  const drawerBleeding = 56;

  const toggleDrawer = (newOpen, boxTitle, key) => () => {
    setOpen(newOpen);
    setOpenedDrawer(key);
    setOpenedBoxTitle(boxTitle);
    let selectedArr = [];
    if (key !== undefined) {
      if (key === "mvp" || key === "lvp") {
        if (gameDetails[key].length > 0) {
          let tempObj = {};
          tempObj.id = gameDetails[key];
          tempObj.name = getPlayerName(gameDetails[key]);
          setSelectedTeamPlayers([tempObj]);
        }
      } else {
        gameDetails[key].forEach((playerId) => {
          let tempObj = {};
          tempObj.id = playerId;
          tempObj.name = getPlayerName(playerId);
          selectedArr.push(tempObj);
        });
        setSelectedTeamPlayers(selectedArr);
      }
    }
  };

  const fetchGameDetails = async () => {
    const gameData = await getDoc(gameRef);
    setGameDetails(gameData.data());
  };

  const fetchUserdata = async () => {
    const userData = await getDoc(userRef);
    if (userData.data().team) {
      const userTeam = await getDoc(userData.data().team);
      if (userTeam.data() == null) {
        toast.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));
      }
      let tempTeamPlayersArray = await getTeamPlayers(userData.data().team);
      setTeamPlayers(tempTeamPlayersArray);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmitScoreForm = async (event) => {
    event.preventDefault();
    await updateDoc(gameRef, {
      homeScore: formValues.homeScore ? formValues.homeScore : null,
      awayScore: formValues.awayScore ? formValues.awayScore : null,
    });
    fetchGameDetails();
  };

  const handleDeleteFromSelectedPlayers = (value) => {
    setSelectedTeamPlayers(
      selectedTeamPlayers.filter((player, index) => index !== value)
    );
  };

  const handleSubmitSwipeableDrawer = async (event) => {
    // update game
    if (openedDrawer === "mvp" || openedDrawer === "lvp") {
      let id = "";
      if (selectedTeamPlayers.length > 0) {
        id = selectedTeamPlayers[0].id;
      }
      await updateDoc(gameRef, {
        [openedDrawer]: id,
      });
    } else {
      let finalArr = selectedTeamPlayers.map((obj) => {
        return obj.id;
      });
      await updateDoc(gameRef, {
        [openedDrawer]: finalArr,
      });
    }
    toast.success(
      t("games.messages.successfullyedited", { openedBoxTitle: openedBoxTitle })
    );
    setOpen(false);
    fetchGameDetails();
  };

  function ScoreForm() {
    return (
      <form className="text-center" onSubmit={handleSubmitScoreForm}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            "> div": {
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          }}
        >
          <Box>
            <TextField
              id="homeScore-input"
              name="homeScore"
              label={gameDetails.homeTeam}
              type="number"
              value={formValues.homeScore ? formValues.homeScore : ""}
              onChange={handleInputChange}
              sx={{ m: 2, backgroundColor: theme.palette.grey.dark }}
              size="small"
            />
          </Box>
          <Box>
            <TextField
              id="awayScore-input"
              name="awayScore"
              label={gameDetails.awayTeam}
              type="number"
              value={formValues.awayScore ? formValues.awayScore : ""}
              onChange={handleInputChange}
              sx={{ m: 2, backgroundColor: theme.palette.grey.dark }}
              size="small"
            />
          </Box>
        </Box>
        
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" type="submit">
            {t("general.save")}
          </Button>
        </Box>
      </form>
    );
  }

  function gameDetailBox(boxTitle, children, key) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.grey.light,
          p: 1,
          my: 1,
          borderRadius: `${theme.shape.borderRadius}px`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
          >
            {boxTitle}
          </Typography>
          {key && (
            <IconButton
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "30px",
                height: "30px",
                aspectRatio: 1,
                "&:active, &:hover": {
                  background: theme.palette.primary.main,
                  color: "white",
                },
              }}
              onClick={toggleDrawer(true, boxTitle, key)}
            >
              <Edit sx={{ color: "white" }} fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.5 }}>
          {children}
        </Box>
      </Box>
    );
  }

  function getPlayerName(playerId) {
    let curPlayer = teamPlayers.find((player) => player.id === playerId);
    return curPlayer.name;
  }

  useEffect(() => {
    fetchUserdata();
    fetchGameDetails();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 1,
        minHeight: "calc(100vh - 10vh)",
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate("/games")}
        sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
      >
        <ChevronLeft />
        {t("general.navigation.back")}
      </Button>
      {loading && <Spinner />}
      {!loading && (
        <Box>
          <Typography
            sx={{ p: 1, color: theme.palette.primary.main, fontWeight: "bold" }}
          >
            {gameDetails.homeTeam} - {gameDetails.awayTeam}
          </Typography>

          {gameDetailBox(t("games.gamedetails.score"), <ScoreForm />)}
          {gameDetailBox(
            t("games.gamedetails.mvp"),
            gameDetails.mvp && (
              <>
                {getPlayerName(gameDetails.mvp)}
                <EmojiEvents sx={{ mr: "10px", ml: "10px" }} />
              </>
            ),
            "mvp"
          )}
          {gameDetailBox(
            t("games.gamedetails.lvp"),
            gameDetails.lvp && (
              <>
                {getPlayerName(gameDetails.lvp)}
                <AssistWalker sx={{ mr: "10px", ml: "10px" }} />
              </>
            ),
            "lvp"
          )}
          {gameDetailBox(
            t("games.gamedetails.goals"),
            <Box sx={{ width: "100%" }}>
              {gameDetails.goalScorers.map((goalscorer, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 0.5,
                  }}
                >
                  {getPlayerName(goalscorer)}
                  <SportsSoccer sx={{ mr: "10px", ml: "10px" }} />
                </Box>
              ))}
            </Box>,
            "goalScorers"
          )}
          {gameDetailBox(
            t("games.gamedetails.assists"),
            <Box sx={{ width: "100%" }}>
              {gameDetails.assistMakers.map((assistMakers, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 0.5,
                  }}
                >
                  {getPlayerName(assistMakers)} <SoccerBoot />
                </Box>
              ))}
            </Box>,
            "assistMakers"
          )}

          {gameDetailBox(
            t("games.gamedetails.yellowcards"),
            <Box sx={{ width: "100%" }}>
              {gameDetails.yellowCards.map((playerKey, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 0.5,
                  }}
                >
                  {getPlayerName(playerKey)}
                  <CardIcon color={"yellow"} />
                </Box>
              ))}
            </Box>,
            "yellowCards"
          )}
          {gameDetailBox(
            t("games.gamedetails.redcards"),
            <Box sx={{ width: "100%" }}>
              {gameDetails.redCards.map((playerKey, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 0.5,
                  }}
                >
                  {getPlayerName(playerKey)} <CardIcon color={"red"} />{" "}
                </Box>
              ))}
            </Box>,
            "redCards"
          )}
        </Box>
      )}
      <SwipeableDrawer
        className="swipeableDrawer"
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: open ? -drawerBleeding : 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
              display: "flex",
              p: 2,
              backgroundColor: "#fff",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textAlign: "center",
                flex: "1",
              }}
            >
              {openedBoxTitle}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={toggleDrawer(false)}>
              <Close sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            backgroundColor: "#fff",
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              overflow: "auto",
              height: "80%",
              width: "100%",
            }}
          >
            <List>
              {teamPlayers.map((player, index) => (
                <ListItemButton
                onClick={() => {
                  if (openedDrawer === "mvp" || openedDrawer === "lvp") {
                    setSelectedTeamPlayers([player]);
                  } else {
                    setSelectedTeamPlayers([...selectedTeamPlayers, player]);
                  }
                }}
                key={index}
                >
                  <Add />
                  <ListItemText primary={player.name} />
                </ListItemButton>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              height: "10%",
            }}
          >
            {
              <Box
                sx={{
                  overflow: "auto",
                  height: "80%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "end",
                }}
              >
                {selectedTeamPlayers.map((player, index) => (
                  <Chip
                    label={player.name}
                    key={index}
                    onDelete={() => handleDeleteFromSelectedPlayers(index)}
                  />
                ))}
              </Box>
            }
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmitSwipeableDrawer}
              autoFocus
            >
              {t("games.gamedetails.update", {
                openedBoxTitle: openedBoxTitle,
              })}
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default GameDetail;
