import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#1B3466",
      light: "#5f7194",
    },
    headers: {
      subheader: "#6C6C70",
    },
    accents: {
      accent1: "#B4EDD2",
      accent2: "#A0CFD3",
      accent3: "#8D94BA",
      accent4: "#9A7AA0",
    },
    grey: {
      light: "#F6F6F7",
      dark: "#EBEBEE",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'SF Pro Display', 'sans-serif'",
  },
  shape: {
    borderRadius: 10,
  },
  fabStyle: {
    position: "fixed !important",
    bottom: 16,
    right: 16,
  },
});
