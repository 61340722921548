import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase-config";

export const fetchFinesWithPlayerId = async (playerId) => {
  let tempFinesArray = [];
  const q = query(
    collection(db, "fine-lines"),
    where("player", "==", playerId)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    tempFinesArray.push({ id: doc.id, details: doc.data() });
  });
  return {player: playerId, fines: tempFinesArray};
};

