import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/userAuthContext";
import NotFound from "./components/NotFound";
import MyTeam from "./components/Team/MyTeam";
import Stats from "./components/Stats/Stats";
import Fines from "./components/Fines/Fines";
import FineDetail from "./components/Fines/FineDetail";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./themes/theme";
import PlayerDetail from "./components/Team/PlayerDetail";
import Drinks from "./components/Drinks/Drinks";
import Games from "./components/Games/Games";
import GameDetail from "./components/Games/GameDetail";
import Finewheel from "./components/Finewheel/Finewheel";
import MyTeamEdit from "./components/Team/MyTeamEdit";

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <UserAuthContextProvider>
        <Routes>
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/drinks"
            element={
              <ProtectedRoute>
                <Drinks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/games"
            element={
              <ProtectedRoute>
                <Games />
              </ProtectedRoute>
            }
          />
           <Route
            path="/games/:gameId"
            element={
              <ProtectedRoute backButton={"/games"}>
                <GameDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/statistics"
            element={
              <ProtectedRoute>
                <Stats />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team"
            element={
              <ProtectedRoute>
                <MyTeam />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team/edit"
            element={
              <ProtectedRoute backButton={"/team"}>
                <MyTeamEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/players/:playerId"
            element={
              <ProtectedRoute backButton={"/team"}>
                <PlayerDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/fines"
            element={
              <ProtectedRoute>
                <Fines />
              </ProtectedRoute>
            }
          />
          <Route
            path="/finewheel"
            element={
              <ProtectedRoute>
                <Finewheel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/fines/:fineId"
            element={
              <ProtectedRoute backButton={"/fines"}>
                <FineDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </UserAuthContextProvider>
    </ThemeProvider>
  );
}
export default App;
