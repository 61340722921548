import React, { useRef, useState } from "react";

import { Box, Typography, useTheme, Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
  deleteObject
} from "@firebase/storage";
import { updateDoc } from "@firebase/firestore";
import { Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import Spinner from "./Spinner";

const UploadImage = ({
  path,
  name,
  toUpdateRef,
  toUpdateAttr,
  currentImage,
}) => {
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  //Translations
  const { t } = useTranslation();
  const [changingFirestoreImage, setChangingFirestoreImage] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [imageURL, setImageURL] = useState(currentImage);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(1);
  const storage = getStorage();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onSubmitCrop() {
    setChangingFirestoreImage(true);

    if (completedCrop) {
      // create a canvas element to draw the cropped image
      const canvas = document.createElement("canvas");

      // get the image element
      const image = imgRef.current;

      // draw the image on the canvas
      if (image) {
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        if (ctx) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = "high";

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
        }

        const base64Image = canvas.toDataURL("image/png"); // can be changed to jpeg/jpg etc

        if (base64Image) {
          uploadString(
            ref(storage, `/${path}/${name}`),
            base64Image,
            "data_url"
          ).then((snapshot) => {
            getDownloadURL(snapshot.ref).then(async (url) => {
              await updateDoc(toUpdateRef, {
                [toUpdateAttr]: url,
              });
              currentImage = url;
              setImageURL(url);
            });
            toast.success(t("imageupload.imagechanged"));
            setChangingFirestoreImage(false);
            setImgSrc("");
          });
        }
      }
    }
  }

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 60,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function deleteImg() {
    setChangingFirestoreImage(true);

    // Delete the file
    deleteObject(ref(storage, `/${path}/${name}`))
      .then(() => {
        // File deleted successfully
        console.log("deleted");
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        toast.warn(t("general.errors.somethingwentwrong"));
      });
    await updateDoc(toUpdateRef, {
      [toUpdateAttr]: null,
    });
    toast.success(t("imageupload.imagedeleted"));
    setChangingFirestoreImage(false);
    setImageURL(null);
  }
  
  function cancelImageCrop(){
    setImgSrc("");
    console.log("stop")

  }

  return (
    <Box>
      {imageURL === null && (
        <Box>
          {t("imageupload.noimageyet")}
          <input
            id="file-input"
            type="file"
            accept="image/*"
            onChange={onSelectFile}
          />
        </Box>
      )}
      {imgSrc.length < 1 && imageURL !== null && (
        <Box sx={{ position: "relative", maxWidth: "75vw" }}>
          <img alt="Current Image" src={imageURL} style={{ width: "100%" }} />
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              display: "flex",
              backgroundColor: "white",
            }}
          >
            <IconButton aria-label="file-input">
              <label style={{ display: "flex" }} htmlFor="file-input">
                <Edit />
              </label>
            </IconButton>

            <IconButton aria-label="delete" onClick={() => deleteImg()}>
              <Delete />
            </IconButton>
          </Box>
          <input
            id="file-input"
            type="file"
            accept="image/*"
            onChange={onSelectFile}
            style={{ display: "none" }}
          />
        </Box>
      )}

      {changingFirestoreImage && <Spinner></Spinner>}
      {imgSrc.length > 1 && !changingFirestoreImage && (
        <>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => {
              setCompletedCrop(c);
            }}
            aspect={aspect}
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => cancelImageCrop()}
            >
              {t("general.cancel")}
            </Button>
            <Button variant="contained" onClick={() => onSubmitCrop()}>
            {t("imageupload.useimage")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UploadImage;
