import { Button } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { React } from "react";
import { useUserAuth } from "../context/userAuthContext";
import { db } from "../firebase-config";
import { randomHexColor } from "../helperFunctions/randomHexColor";

function Profile(props) {
  const { user } = useUserAuth();
  // const userRef = db.collection('users').doc(user.uid);
  const userRef = doc(db, "users", user.uid);

  const ChangeColorOnProfile = async () => {
    await updateDoc(userRef, {
      color:  randomHexColor()
    });
  }

  return (
    <div>
      <div>
        <h1>Profile</h1>
        <p>{user.displayName}</p>
        <p>{user.uid}</p>
      </div>
      <hr />
        <Button variant="contained" onClick={ChangeColorOnProfile} >Generate new color</Button>
    </div>
  );
}

export default Profile;
