import React, { useEffect, useState } from "react";
import { Typography, useTheme, Box, Tabs, Tab } from "@mui/material";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useUserAuth } from "../../context/userAuthContext";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";
import SwipeableViews from "react-swipeable-views";
import Spinner from "../helper-components/Spinner";
import GameStats from "./GameStats";
import FineStats from "./FineStats";
import { useTranslation } from "react-i18next";

const Statistics = () => {
  //Translations
  const { t } = useTranslation();

  const [teamPlayers, setTeamPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [fines, setFines] = useState([]);
  const [fineLines, setFineLines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const { user } = useUserAuth();
  const theme = useTheme();

  const userRef = doc(db, "users", user.uid);
  let navbarHeight = "104px";

  useEffect(() => {
    // Fetch team for current logged-in user
    const fetchTeamForPlayer = async () => {
      try {
        const userData = await getDoc(userRef);

        if (userData.data().team !== null) {
          let tempTeamPlayersArray = await getTeamPlayers(userData.data().team);
          setTeamPlayers(tempTeamPlayersArray);
          setLoading(false);
        } else {
          //   toast.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));;
        }
      } catch (error) {
        console.error("Error fetching team players: ", error);
      }
    };

    // Fetch games
    const fetchGames = async () => {
      try {
        const gamesCollection = collection(db, "games");
        const querySnapshot = await getDocs(gamesCollection);
        const fetchedGames = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })); // Include the id field in fetched games data
        setGames(fetchedGames);
      } catch (error) {
        console.error("Error fetching games: ", error);
      }
    };
    const fetchFineData = async () => {
      try {
        const finesSnapshot = await getDocs(collection(db, "fines"));
        const fineLinesSnapshot = await getDocs(collection(db, "fine-lines"));

        const finesData = finesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFines(finesData);
        const fineLinesData = fineLinesSnapshot.docs.map((doc) => doc.data());
        setFineLines(fineLinesData);

        // setFineStatistics(playerFineStatistics);
      } catch (error) {
        console.error("Error fetching fines and fine-lines data:", error);
      }
    };

    fetchFineData();

    fetchTeamForPlayer();
    fetchGames();
  }, []);

  const styles = {
    slide: {
      paddingTop: 15,
      minHeight: `calc(100vh - ${navbarHeight} )`,
    },
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{ minHeight: "calc(100vh - 45px)" }}
        {...other}
      >
        {value === index && children}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        p: 1,
        minHeight: "calc(100vh - 10vh)",
      }}
    >
      <Typography
        sx={{ p: 1, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {t("navmenu.stats")}
      </Typography>
      <Tabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab
          label={t("navmenu.games")}
          sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
        />
        <Tab
          label={t("navmenu.fines")}
          sx={{ minWidth: "fit-content", flex: 1, maxWidth: "none" }}
        />
      </Tabs>

      <SwipeableViews
        enableMouseEvents
        onChangeIndex={handleChangeIndex}
        index={value}
      >
        <TabPanel style={Object.assign({}, styles.slide)}>
          {loading && <Spinner />}
          {!loading && value === 0 && (
            <GameStats games={games} teamPlayers={teamPlayers} />
          )}
        </TabPanel>
        <TabPanel style={Object.assign({}, styles.slide)}>
          {!loading && value === 1 && (
            <FineStats
              fines={fines}
              fineLines={fineLines}
              teamPlayers={teamPlayers}
            />
          )}
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

export default Statistics;
