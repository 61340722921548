import {
  Box,
  Button,
  Fab,
  FormControl,
  IconButton,
  SwipeableDrawer,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Wheel } from "react-custom-roulette";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { useUserAuth } from "../../context/userAuthContext";
import { Add, Close } from "@mui/icons-material";
import Spinner from "../helper-components/Spinner";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";

const Finewheel = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const drawerBleeding = 56;

  const [finewheelPenalties, setFinewheelPenalties] = useState();
  const [penaltyName, setPenaltyName] = useState("");
  const [penaltyDescription, setPenaltyDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState();
  const [mustSpin, setMustSpin] = useState(false);

  const [prizeNumber, setPrizeNumber] = useState(-1);
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(
        Math.random() * finewheelPenalties.length
      );
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const fetchAllFinewheelPenalties = async () => {
    let tempPenaltiesArr = [];
    const userData = await getDoc(userRef);
    setFirebaseUser(userData.data());
    if (userData.data().team) {
      const q = query(
        collection(db, "finewheel-penalties"),
        where("team", "==", userData.data().team)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let tempPenaltyObj = {};
        tempPenaltyObj.option = doc.data().name.substr(0, 10) + "\u2026";
        tempPenaltyObj.name = doc.data().name;
        tempPenaltyObj.description = doc.data().description;
        tempPenaltiesArr.push(tempPenaltyObj);
      });

      setFinewheelPenalties(tempPenaltiesArr);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Creating finewheelpenalty");

    if (
      penaltyName.trim().length < 1 ||
      penaltyName === undefined
      // || penaltyDescription === undefined
      // || penaltyDescription.trim().length < 1
    ) {
      toast.error(t("finewheel.missingfields"));
    } else {
      await addDoc(collection(db, "finewheel-penalties"), {
        name: penaltyName,
        description: penaltyDescription,
        team: firebaseUser.team,
      });

      setOpen(false);
      setPenaltyName("");
      setPenaltyDescription("");
      toast.success(t("finewheel.penaltyadded"));
      setPrizeNumber(-1);
      fetchAllFinewheelPenalties();
    }
  };

  useEffect(() => {
    fetchAllFinewheelPenalties();
  }, []);

  return (
    <Box>
      <Typography
        sx={{ p: 2, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {t("navmenu.finewheel")}
      </Typography>
      {loading && <Spinner />}

      {!loading && finewheelPenalties.length > 0 && (
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={finewheelPenalties}
              backgroundColors={[
                theme.palette.accents.accent1,
                theme.palette.accents.accent2,
                theme.palette.accents.accent3,
                theme.palette.accents.accent4,
              ]}
              fontFamily="Poppins"
              spinDuration={0.5}
              disableInitialAnimation={true}
              onStopSpinning={() => {
                setMustSpin(false);
                toast.success(finewheelPenalties[prizeNumber].name);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button variant="contained" onClick={handleSpinClick}>
              {t("finewheel.spin")}
            </Button>
          </Box>
          {!mustSpin && prizeNumber !== -1 && (
            <Box
              sx={{
                mt: 2,
                mb: 6
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                {finewheelPenalties[prizeNumber].name}
              </h2>
              <p>{finewheelPenalties[prizeNumber].description}</p>
            </Box>
          )}
        </Box>
      )}
      {/* Emptystate */}
      {!loading && finewheelPenalties.length < 1 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <EmptyStateComponent2
            heading={t("finewheel.nopenalties")}
            caption={t("finewheel.addpenaltytoseewheel")}
            parentComponent={true}
          />{" "}
        </Box>
      )}
      <Box sx={{ textAlign: "center", pt: 1 }}>
        <Fab
          sx={theme.fabStyle}
          color="primary"
          aria-label="add"
          onClick={toggleDrawer(true)}
        >
          <Add />
        </Fab>
      </Box>
      <SwipeableDrawer
        className="swipeableDrawer"
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: open ? -drawerBleeding : 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
              display: "flex",
              p: 2,
              backgroundColor: "#fff",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textAlign: "center",
                flex: "1",
              }}
            >
              {t("finewheel.addpenalty")}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={toggleDrawer(false)}>
              <Close sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl fullWidth sx={{ ">div": { margin: 1 }, flex: 1 }}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label={t("finewheel.name")}
              value={penaltyName}
              type="text"
              fullWidth
              required
              variant="standard"
              onChange={(e) => setPenaltyName(e.target.value)}
            />
            <TextField
              // required
              autoFocus
              margin="dense"
              id="value"
              name="value"
              label={t("finewheel.description")}
              type="text"
              fullWidth
              value={penaltyDescription}
              multiline
              variant="standard"
              onChange={(e) => setPenaltyDescription(e.target.value)}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            autoFocus
          >
            {t("finewheel.createpenalty")}
          </Button>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default Finewheel;
