import {
    collection,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    where,
  } from "firebase/firestore";
  import { db } from "../firebase-config";


  export const getTeamPlayers = async (teamRef) => {
    let tempTeamPlayersArray = [];
  
    const q = query(
      collection(db, "users"),
      where("team", "==", teamRef),
      orderBy("name", "asc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        let tempuserObj = doc.data();
        tempuserObj.id = doc.id;
        tempTeamPlayersArray.push(tempuserObj);
    });
    return tempTeamPlayersArray;
  };