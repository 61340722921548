import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";


const Top3Card = (props) => {
  const { player, result } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  let genericUserImageURL =
    "https://firebasestorage.googleapis.com/v0/b/react-firebase-auth-temp-83768.appspot.com/o/files%2Fblank-profile-picture-g998e22640_1280.png?alt=media&token=6eef0002-3e03-49f6-b51a-bc2b28047dbd";

  return (
    <Card
      key={player.id}
      sx={{
        mx: 1,
        flex: " 0 0 auto",
        width: 130,
        height: 130,
        display: "flex",
        m: 1,
        flexDirection: "column",
      }}
      onClick={() => navigate(`/players/${player.id}`)}

    >
      <Box
        sx={{
          position: "relative",
          flex: 5,
        }}
      >
        <CardMedia
          sx={{
            height: 100,
          }}
          image={
            player.imageURL != null && player.imageURL.length > 1 ? player.imageURL : genericUserImageURL
          }
          title={player.name}
        />
        <Typography
          sx={{
            position: "absolute",
            display: "flex",
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            color: theme.palette.common.white,
            bottom: 0,
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {player.name}
        </Typography>
      </Box>
      <CardContent
        sx={{
          p: 0,
          paddingBottom: "0 !important",
          display: "flex",
          flex: 2,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="p" component="div">
          {result}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Top3Card;
