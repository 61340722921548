import { React, useState, useEffect } from "react";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  where,
  query,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EmptyStateComponent from "../helper-components/EmptyStateComponent";
import Spinner from "../helper-components/Spinner";

import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase-config";
import {
  Box,
  Button,
  Fab,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ChevronRight, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const Fines = () => {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [firebaseUser2, setFirebaseUser] = useState();
  const [teamFines, setTeamFines] = useState([]);
  const [fineName, setFineName] = useState("");
  const [fineValue, setFineValue] = useState(0);

  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);

  const drawerBleeding = 56;

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const fetchAllFines = async () => {
    let tempTeamFinesArray = [];
    const userData = await getDoc(userRef);
    setFirebaseUser(userData.data());
    if (userData.data().team) {
      const q = query(
        collection(db, "fines"),
        where("team", "==", userData.data().team)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let tempFineObj = doc.data();
        tempFineObj.id = doc.id;
        tempTeamFinesArray.push(tempFineObj);
      });

      setTeamFines(tempTeamFinesArray);

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Creating fine");
    await addDoc(collection(db, "fines"), {
      amount: Number(fineValue),
      name: fineName,
      team: firebaseUser2.team,
    });

    setOpen(false);
    setFineName("");
    setFineValue(0);
    toast.success("Fine Created");
    fetchAllFines();
  };

  useEffect(() => {
    fetchAllFines();
  }, []);

  return (
    <Box>
      <Typography
        sx={{ p: 2, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {t("navmenu.fines")}
      </Typography>
      {loading && <Spinner />}
      {teamFines.length < 1 && !loading && (
        <EmptyStateComponent
          heading={t("fines.nofinesfound")}
          caption={t("fines.addfinesusing+")}
        />
      )}
      {teamFines.length > 0 && (
        <List sx={{ backgroundColor: theme.palette.common.white, p: 1, mb: 6 }}>
          {teamFines.map((fine) => (
            <ListItem key={fine.id} disablePadding>
              <ListItemButton onClick={() => navigate(fine.id)}>
                <ListItemText
                  primary={fine.name}
                  secondary={t("intlCurrencyWithOptions", {
                    val: fine.amount,
                  })}
                />
                <ChevronRight />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      <Box sx={{ textAlign: "center", pt: 1 }}>
        <Fab
          sx={theme.fabStyle}
          color="primary"
          aria-label="add"
          onClick={toggleDrawer(true)}
        >
          <Add />
        </Fab>
      </Box>
      <SwipeableDrawer
        className="swipeableDrawer"
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: open ? -drawerBleeding : 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
              display: "flex",
              p: 2,
              backgroundColor: "#fff",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textAlign: "center",
                flex: "1",
              }}
            >
             {t("fines.addfine")}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={toggleDrawer(false)}>
              <Close sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl fullWidth sx={{ ">div": { margin: 1 }, flex: 1 }}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label={t("fines.finename")}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setFineName(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="value"
              name="value"
              label={t("fines.finevalue")}
              type="number"
              fullWidth
              variant="standard"
              onChange={(e) => setFineValue(e.target.value)}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            autoFocus
          >
             {t("fines.createfine")}
          </Button>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default Fines;
