import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchAllPossibleFines } from "../../helperFunctions/GetAllPossibleFines";
import { fetchFinesWithPlayerId } from "../../helperFunctions/GetFinesForPlayer";
import Spinner from "../helper-components/Spinner";

const HomeHeaderComponent = (props) => {
  //Translations
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState();
  let user = props.user;
  let firebaseUser = props.firebaseUser;

  const combineFinesForAllPlayers_AllFines = async () => {
    //Get all Fines for the team
    let allFines = await fetchAllPossibleFines(firebaseUser.team.id);

    //Get all fine-lines for the teamplayers
    let allFinesArray = [];
    for (let teamPlayer of props.teamPlayers) {
      let finesForPlayer = await fetchFinesWithPlayerId(teamPlayer.id);
      allFinesArray.push(finesForPlayer.fines);
    }
    allFinesArray = allFinesArray.flat();

    //Combine the two
    const totalAmount = allFinesArray
      .map((fineLine) =>
        allFines.find((fine) => fine.id === fineLine.details.fine)
      )
      .filter(Boolean)
      .reduce((total, fine) => total + fine.details.amount, 0);
    setTotalAmount(totalAmount);
    setLoading(false);
  };

  useEffect(() => {
    combineFinesForAllPlayers_AllFines();
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        textAlign: "center",
      }}
    >
      <h1>{t("general.appname")}</h1>
      {t("home.headercomponent.hi")} {user && user.displayName}
      <br />
      {loading && <Spinner />}
      {!loading && <Typography variant="p">
      {t("home.headercomponent.totalamountissuedfines")}{t("intlCurrencyWithOptions", { val: totalAmount })}
        </Typography>}
      
    </Box>
  );
};

export default HomeHeaderComponent;
