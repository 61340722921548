import { React, useState, useEffect } from "react";
import { doc, collection, getDoc, getDocs } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { Box, Button } from "@mui/material";
import { db } from "../../firebase-config";
import Spinner from "../helper-components/Spinner";

import { toast } from "react-toastify";
import Top3v2 from "./Top3v2";
import HomeHeaderComponent from "./HomeHeaderComponent";
import { getTeamPlayers } from "../../helperFunctions/GetTeamPlayers";
import { useTranslation } from "react-i18next";
import Top3 from "./Top3";
import { getGamesForTeam } from "../../helperFunctions/GetRelatedGames";

const Home = () => {
  //Translations
  const { t } = useTranslation();

  const { user } = useUserAuth();
  const userRef = doc(db, "users", user.uid);

  const [loading, setLoading] = useState(true);

  const [firebaseUser, setFirebaseUser] = useState();
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [noTeam, setNoTeam] = useState(false);
  const fetchUserdata = async () => {
    const userData = await getDoc(userRef);
    setFirebaseUser(userData.data());

    if (userData.data().team !== null) {
      const userTeam = await getDoc(userData.data().team);
      if (userTeam.data() == null) {
        toast.error(t("general.errors.teamlinkedtoaccountdoesnotexist"));
      }
      let userTeamObj = userTeam.data();
      userTeamObj.id = userTeam.id;
      // setTeam(userTeamObj);
      // get players for team
      let tempTeamPlayersArray = await getTeamPlayers(userData.data().team);
      setTeamPlayers(tempTeamPlayersArray);
      const games = await getGamesForTeam(userData.data().team);
      setGames(games);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("You're not linked to a team yet");
      setNoTeam(true);
    }
  };
  function notifyMe() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification("Notification with ServiceWorker");
      });
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification("Notification with ServiceWorker");
          });
          // …
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }
  useEffect(() => {
    fetchUserdata();
  }, []);

  return (
    <Box>
      {loading && <Spinner />}
      {noTeam && (
        <div>
          Navigate to team
          <Button>should navigate to team</Button>
        </div>
      )}

      {!loading && !noTeam && (
        <HomeHeaderComponent
          user={user}
          firebaseUser={firebaseUser}
          teamPlayers={teamPlayers}
        />
      )}
      {!loading && teamPlayers.length > 0 && (
        <Box>
          <Top3
            title={t("home.top3.mostdrinksbought")}
            type="drinksBought"
            players={teamPlayers}
          />

          <Top3
            title={t("home.top3.mostamountfines")}
            type="fines"
            players={teamPlayers}
          />

          <Top3
            title={t("home.top3.mostgoalsscored")}
            type="goalScorers"
            players={teamPlayers}
            games={games}
          />

          <Top3
            title={t("home.top3.mostassists")}
            type="assistMakers"
            players={teamPlayers}
            games={games}
          />

          <Top3
            title={t("home.top3.mostredcards")}
            type="redCards"
            players={teamPlayers}
            games={games}
          />

          <Top3
            title={t("home.top3.mostyellowcards")}
            type="yellowCards"
            players={teamPlayers}
            games={games}
          />
        </Box>
      )}
    </Box>
  );
};

export default Home;
