import { React, useState, useEffect } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useTranslation } from "react-i18next";

import { useUserAuth } from "../context/userAuthContext";

import { doc, getDoc, updateDoc } from "firebase/firestore";

import { db } from "../firebase-config";

import Spinner from "./helper-components/Spinner";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { maxWidth } from "@mui/system";
import { toast } from "react-toastify";
import UploadImage from "./helper-components/UploadImage";

function Settings(props) {
  //Translations
  const { t } = useTranslation();

  const { user } = useUserAuth();
  const theme = useTheme();

  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [firebaseUser, setFirebaseUser] = useState();
  const [displayname, setDisplayName] = useState("");
  const [settingLanguage, setSettingLanguage] = useState("nl");
  const userRef = doc(db, "users", user.uid);

  const fetchUserdata = async () => {
    const docSnap = await getDoc(userRef);
    setSettingLanguage(docSnap.data().lang);
    setDisplayName(docSnap.data().name);
    setFirebaseUser(docSnap.data());
    setLoading(false);
  };

  const handleLanguageChange = async (event, newAlignment) => {
    setSettingLanguage(newAlignment);
    i18n.changeLanguage(newAlignment);
    await updateDoc(userRef, {
      lang: newAlignment,
    });
  };

  const handleNameSubmit = async () => {
    if (displayname.trim() === "") {
      toast.warn(t("settings.emptydisplayname"));
    } else {
      try {
        await updateDoc(userRef, {
          name: displayname,
        });
        toast.success(t("settings.displaynamechanged"));
      } catch {
        toast.warn(t("general.errors.somethingwentwrong"));
      }
    }
  };

  useEffect(() => {
    fetchUserdata();
  }, []);

  return (
    <Box>
      <Typography
        sx={{ p: 1, color: theme.palette.primary.main, fontWeight: "bold" }}
      >
        {t("settingsmenu.settings")}
      </Typography>
      {loading && <Spinner />}
      {!loading && (
        <Box sx={{ "> div": { m: 1 } }}>
          <Box sx={{ "> div": { my: 2 } }}>
            <Typography sx={{ color: theme.palette.headers.subheader }}>
              {t("settings.profilepic")}
            </Typography>
            <UploadImage
              path={"files"}
              name={firebaseUser.uid}
              toUpdateRef={userRef}
              toUpdateAttr={"imageURL"}
              currentImage={firebaseUser.imageURL}
            ></UploadImage>
          </Box>
          <Box sx={{ "> div": { my: 2 } }}>
            <Typography
              sx={{
                color: theme.palette.headers.subheader,
                textTransform: "capitalize",
              }}
            >
              {t("accountdetails.displayname")}
            </Typography>
            <TextField
              value={displayname}
              margin="normal"
              fullWidth
              name="nickName"
              label={t("accountdetails.displayname")}
              type="text"
              id="nickName"
              autoFocus
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <Button variant="contained" onClick={() => handleNameSubmit()}>
              {" "}
              {t("general.save")}
            </Button>
          </Box>
          <Box sx={{ "> div": { my: 2 } }}>
            <Typography sx={{ color: theme.palette.headers.subheader }}>
              {t("settings.language")}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={settingLanguage}
              exclusive
              onChange={handleLanguageChange}
              aria-label="Platform"
            >
              <ToggleButton value="en">EN</ToggleButton>
              <ToggleButton value="nl">NL</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Settings;
