import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import GoogleButton from "react-google-button";
import { useTranslation } from "react-i18next";
import { useUserAuth } from "../context/userAuthContext";
import Container from "@mui/material/Container";
import {
  Alert,
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

const Signup = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [nickName, setNickName] = useState("");
  const [password, setPassword] = useState("");
  const { signUp, googleSignIn } = useUserAuth();
  let navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  let sp = new URLSearchParams(location.search);

  //Translations
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    let teamId = null;
    if (sp.has("teamId")) {
      teamId = sp.get("teamId");
    }
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password, nickName, teamId);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };
  const handleGoogleSubmit = async (e) => {
    let teamId = null;
    if (sp.has("teamId")) {
      teamId = sp.get("teamId");
    }
    e.preventDefault();
    setError("");
    try {
      await googleSignIn(teamId);
      // await signUp(email, password, nickName, teamId);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    document.body.classList.add("color-page");
    return () => {
      document.body.classList.remove("color-page");
    };
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ color: theme.palette.common.white, mb: 10 }}
        variant="h4"
      >
        {t("general.appname")}
      </Typography>
      <Box>
        <Box
          sx={{
            p: 4,
            border: "1px solid #dfdfdf",
            borderRadius: `${theme.shape.borderRadius}px`,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <h2>{t("accountdetails.signup")}</h2>
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="nickName"
              label={t("accountdetails.displayname")}
              type="text"
              id="nickName"
              autoFocus
              onChange={(e) => setNickName(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("accountdetails.email")}
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("accountdetails.password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button variant="contained" type="Submit" fullWidth sx={{ mt: 2 }}>
            {t("accountdetails.signup")}
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <GoogleButton
              className="g-btn"
              type="dark"
              onClick={handleGoogleSubmit}
              label= {t("accountdetails.googlesignin")}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: 4,
            marginTop: 2,
            textAlign: "center",
            border: "1px solid #dfdfdf",
            backgroundColor: "#fff",
          }}
        >
          {t("accountdetails.alreadyaccount")} <Link to="/login">{t("accountdetails.login")}</Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Signup;
