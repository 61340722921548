import { React, useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Modal,
  Typography,
} from "@mui/material";
import { doc, deleteDoc } from "firebase/firestore";

import { convertDate } from "../../helperFunctions/convertDate";
import { toggleFinePaid } from "../../helperFunctions/toggleFinePaid";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import { useTranslation } from "react-i18next";
import useLongPress from "../../helperFunctions/useLongPress";
import { db } from "../../firebase-config";
import { toast } from "react-toastify";

const PlayerFinesTab = (props) => {
  //Translations
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [longPressedFineLineId, setLongPressedFineLineId] = useState("");
  let allFines = props.allFines;
  let fines = props.fines;

  const handleOpen = (fineLineId) => {
    setOpen(true);
    setLongPressedFineLineId(fineLineId);
  };
  const handleClose = () => {
    setOpen(false);
    setLongPressedFineLineId("");
  };

  function getFineName(fineKey) {
    let curFine = allFines.find((fine) => fine.id === fineKey);
    return curFine.details.name;
  }
  const onLongPress = (event) => {
    console.log("longpress is triggered");
    handleOpen(event.target.attributes.attrfineline.value);
  };

  const onClick = () => {};

  const longPressEvent = useLongPress(onLongPress, onClick, {
    shouldPreventDefault: true,
    delay: 500,
  });

  const handleDeleteFineLine = async (longPressedFineLineIdParam) => {
    console.log("should delete ", longPressedFineLineIdParam);
    try {
      await deleteDoc(doc(db, "fine-lines", longPressedFineLineIdParam));
      toast.success("succesfully deleted");
    } catch (err) {
      toast.error(t("general.errors.somethingwentwrong"));
      console.log(err);
    }
    handleClose();
  };

  return (
    <div>
      {
        <Box sx={{ backgroundColor: theme.palette.common.white, p: 0 }}>
          {fines.length < 1 && (
            <EmptyStateComponent2
              heading={t("team.player.finestab.nofinesyet", {
                playerName: props.player.name,
              })}
            />
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: `${theme.shape.borderRadius}px`,
              }}
            >
              <Typography id="modal-modal-title">
                {t("fines.finehistory.confirmdelete")}
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}
              >
                <Button variant="outlined" onClick={() => handleClose()}>
                  {t("general.cancel")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteFineLine(longPressedFineLineId)}
                >
                  {t("general.yes")}
                </Button>
              </Box>
            </Box>
          </Modal>
          {fines.length > 0 && (
            <TableContainer>
              <Table aria-label="simple table" sx={{ "td, th": { border: 0 } }}>
                <caption># {fines.length}</caption>
                <TableHead>
                  <TableRow sx={{ color: theme.palette.text.secondary }}>
                    <TableCell sx={{ paddingY: 0, color: "inherit" }}>
                      {t("general.tablecolumns.date")}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{ paddingY: 0, color: "inherit" }}
                      align="right"
                    >
                      {t("fines.finehistory.paid")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fines.map((fineLine) => (
                    <TableRow
                      attrfineline={fineLine}
                      key={fineLine.id}
                      {...longPressEvent}
                    >
                      <TableCell attrfineline={fineLine.id}>
                        {convertDate(fineLine.details.created.toDate())}
                      </TableCell>
                      <TableCell attrfineline={fineLine.id}>
                        {getFineName(fineLine.details.fine)}
                      </TableCell>
                      <TableCell align="right" attrfineline={fineLine.id}>
                        {fineLine.details.paid ? (
                          <span style={{ color: "green" }}>
                            {t("fines.finehistory.paid")}
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {t("fines.finehistory.notpaid")}
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ p: "0.5em" }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            toggleFinePaid(fineLine.id, fineLine.details.paid);
                            props.callBack(!props.rerenderValue);
                          }}
                        >
                          {fineLine.details.paid
                            ? t("fines.finehistory.unpay")
                            : t("fines.finehistory.pay")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      }
    </div>
  );
};

export default PlayerFinesTab;
