import React from "react";

function NotFound(props) {
  return (
    <div>
      <h1>Oops! This page doesn't exist yet</h1>
    </div>
  );
}

export default NotFound;
