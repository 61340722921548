import React from "react";
import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import TotalFineAmountPerPlayer from "../helper-components/general/TotalFineAmountPerPlayer";
import { useTranslation } from "react-i18next";


const PlayersList = (props) => {
  //Translations
  const { t } = useTranslation()

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ p: 1, color: theme.palette.headers.subheader }}>{t("team.players")}</Typography>
      <Box sx={{ backgroundColor: theme.palette.common.white, p: 1, mb: 6 }}>
        <TableContainer>
          <Table aria-label="simple table" sx={{ "td, th": { border: 0 } }}>
            <TableHead>
              <TableRow sx={{ color: theme.palette.text.secondary }}>
                <TableCell sx={{ paddingY: 0, color: "inherit" }}>
                  {t("general.tablecolumns.player")}
                </TableCell>
                <TableCell sx={{ paddingY: 0, color: "inherit" }} align="right">
                {t("general.tablecolumns.player")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.teamPlayers.map((player) => (
                <TableRow key={player.id}>
                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      key={player.id}
                      src={
                        player.imageURL === null
                          ? "brokenimage.jpg"
                          : player.imageURL
                      }
                      alt={player.name}
                      sx={{
                        bgcolor: player.imageURL === null ? player.color: "none",
                        width: 28,
                        height: 28,
                        fontSize: "12px",
                      }}
                    />
                    <span style={{ marginLeft: "10px" }}>{player.name}</span>
                  </TableCell>
                  <TableCell align="right"> <TotalFineAmountPerPlayer player={player} /> </TableCell>
                  <TableCell sx={{ p: 0 }} align="center">
                    <Button
                      sx={{ minWidth: 0, p: 0 }}
                      onClick={() => navigate(`/players/${player.id}`)}
                    >
                      <ChevronRight />{" "}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PlayersList;
