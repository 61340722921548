import React, { useState } from "react";
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  Modal,
  Typography,
  Button
} from "@mui/material";
import { doc, deleteDoc } from "firebase/firestore";

import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import { convertDateTime } from "../../helperFunctions/convertDate";
import { LocalDrink, SportsBar } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useLongPress from "../../helperFunctions/useLongPress";
import { db } from "../../firebase-config";
import { toast } from "react-toastify";


const PlayerDrinksTab = (props) => {
  //Translations
  const { t } = useTranslation();

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [longPressedDrinkLineId, setLongPressedDrinkLineId] = useState("");

    const handleOpen = (fineLineId) => {
    setOpen(true);
    setLongPressedDrinkLineId(fineLineId);
  };
  const handleClose = () => {
    setOpen(false);
    setLongPressedDrinkLineId("");
  };
  const [page, setPage] = useState(0);
  let rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onLongPress = (event) => {
    console.log("longpress is triggered");
    console.log(event.target.attributes);
    handleOpen(event.target.attributes.attrdrinkline.value);
  };

  const onClick = () => {};

  const longPressEvent = useLongPress(onLongPress, onClick, {
    shouldPreventDefault: true,
    delay: 500,
  });

  const handleDeleteDrinkLine = async (longPressedDrinkLineId) => {
    console.log("should delete ", longPressedDrinkLineId);
    try {
      await deleteDoc(doc(db, "drink-lines", longPressedDrinkLineId));
      toast.success("succesfully deleted");
    } catch (err) {
      toast.error(t("general.errors.somethingwentwrong"));
      console.log(err);
    }
    handleClose();
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.common.white, p: 1 }}>
      {props.drinks.drinksArray.length < 1 && (
        <EmptyStateComponent2
          heading={t("team.player.drinkstab.playerdidnotdrinkyet", {
            playerName: props.player.name,
          })}
        />
      )}
      <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: `${theme.shape.borderRadius}px`,
              }}
            >
              <Typography id="modal-modal-title">
                {t("fines.finehistory.confirmdelete")}
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}
              >
                <Button variant="outlined" onClick={() => handleClose()}>
                  {t("general.cancel")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteDrinkLine(longPressedDrinkLineId)}
                >
                  {t("general.yes")}
                </Button>
              </Box>
            </Box>
          </Modal>
      {props.drinks.drinksArray.length > 0 && (
        <Box>
          <TableContainer>
            <Table aria-label="simple table" sx={{ "td, th": { border: 0 } }}>
              <TableHead>
                <TableRow sx={{ color: theme.palette.text.secondary }}>
                  <TableCell sx={{ paddingY: 0, color: "inherit" }}>
                    {t("general.tablecolumns.date")}
                  </TableCell>
                  <TableCell
                    sx={{ paddingY: 0, color: "inherit" }}
                    align="center"
                  >
                    {t("general.tablecolumns.createdby")}
                  </TableCell>
                  <TableCell
                    sx={{ paddingY: 0, color: "inherit" }}
                    align="right"
                  >
                    {t("general.tablecolumns.amountdrank")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.drinks.drinksArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((drinkLine) => (
                    <TableRow key={drinkLine.id} attrdrinkline={drinkLine.id} 
                    {...longPressEvent}>
                      <TableCell
                        sx={{ paddingY: 0, color: "inherit" }}
                        attrdrinkline={drinkLine.id}
                      >
                        {convertDateTime(drinkLine.details.createdAt.toDate())}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          paddingY: 0,
                          color: "inherit",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        attrdrinkline={drinkLine.id}
                      >
                        <Avatar
                          alt={drinkLine.player.name}
                          src={
                            drinkLine.player.imageURL === null
                              ? "brokenimage.jpg"
                              : drinkLine.player.imageURL
                          }
                          sx={
                            drinkLine.player.imageURL === null
                              ? {
                                  bgcolor: drinkLine.player.color,
                                  width: 25,
                                  height: 25,
                                }
                              : { bgcolor: "none", width: 25, height: 25 }
                          }
                          attrdrinkline={drinkLine.id}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ paddingY: 0.5 }}
                        attrdrinkline={drinkLine.id}
                      >
                        <Box
                          sx={{
                            paddingY: 0,
                            color: "inherit",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                          attrdrinkline={drinkLine.id}
                        >
                          {drinkLine.details.type === "bought" ? (
                            <SportsBar />
                          ) : (
                            <LocalDrink/>
                          )}
                          {drinkLine.details.amount}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={props.drinks.drinksArray.length}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t("general.tablepaginationof")}  ${
                count !== -1 ? count : `More than${to}}`
              }`
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default PlayerDrinksTab;
