import { AssistWalker, EmojiEvents, SportsSoccer } from "@mui/icons-material";
import { Chip, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import EmptyStateComponent2 from "../helper-components/EmptyStateComponent2";
import CardIcon from "../helper-components/icons/CardIcon";
import SoccerBoot from "../helper-components/icons/SoccerBoot";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const PlayerGamesTab = (props) => {
  //Translations
  const { t } = useTranslation()

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <div>
      {props.relatedGames.relevantGames.length < 1 && (
        <EmptyStateComponent2
          heading={t("team.player.gamestab.notrelevant", {playerName : props.player.name})}
        />
      )}
      {props.relatedGames.relevantGames.map((game) => {
        return (
          <Box
            key={game.id}
            sx={{
              ">div": {
                p: 1,
              },
              m: 1,
            }}
          >
            <Box
            onClick={() => navigate(`/games/${game.id}`)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                borderTopLeftRadius: `${theme.shape.borderRadius}px`,
                borderTopRightRadius: `${theme.shape.borderRadius}px`,
                fontWeight: "bold",
              }}
            >
              {game.homeTeam}
              <Chip
                sx={{
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.primary.main,
                  mx: 1,
                }}
                label={`${game.homeScore ? game.homeScore : "?"} - ${
                  game.awayScore ? game.awayScore : "?"
                }`}
              />
              {game.awayTeam}
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.grey.light,
                borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
                borderBottomRightRadius: `${theme.shape.borderRadius}px`,
                display: "flex",
                flexWrap: "wrap",
                "> *": {
                  mx: "8px !important",
                },
              }}
            >
              {game.mvp === props.playerId && <EmojiEvents />}
              {game.lvp === props.playerId && <AssistWalker />}

              {game.goalScorers.length > 0 &&
                game.goalScorers.map((player, index) => {
                  if (player === props.playerId) {
                    return (
                      <SportsSoccer
                        key={`goalsScorer${index}`}
                        sx={{ mr: "10px", ml: "10px" }}
                      />
                    );
                  }
                })}
              {game.assistMakers.length > 0 &&
                game.assistMakers.map((player, index) => {
                  if (player === props.playerId) {
                    return <SoccerBoot key={`goalsScorer${index}`} />;
                  }
                })}
              {game.redCards.length > 0 &&
                game.redCards.map((player, index) => {
                  if (player === props.playerId) {
                    return <CardIcon key={`redCards${index}`} color={"red"} />;
                  }
                })}
              {game.yellowCards.length > 0 &&
                game.yellowCards.map((player, index) => {
                  if (player === props.playerId) {
                    return (
                      <CardIcon key={`yelCards${index}`} color={"yellow"} />
                    );
                  }
                })}
            </Box>
          </Box>
        );
      })}
    </div>
  );
};

export default PlayerGamesTab;
