import React from "react";
import EmptyState from "@pluralsight/ps-design-system-emptystate";
import { Box } from "@mui/material";

const EmptyStateComponent2 = (props) => {
  return (
    <>
      <Box
       
      >
        <EmptyState
          illustration={
            <EmptyState.Illustration
              name={EmptyState.Illustration.names.magnify}
            />
          }
          heading={<EmptyState.Heading>{props.heading}</EmptyState.Heading>}
          caption={<EmptyState.Caption>{props.caption}</EmptyState.Caption>}
          size={EmptyState.sizes.small}
        />
      </Box>
    </>
  );
};

export default EmptyStateComponent2;
